import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

import ScrollToTop from './components/Common/ScrollToTop/ScrollToTop';

import Preloader from './components/Common/Preloader/Preloader';
import Header from './components/Common/Header/Header';
import Footer from './components/Common/Footer/Footer';

import HomePage from './Views/HomePage';
import Expertise from './Views/Expertise';
import SkillSet from './Views/SkillSet';
import ExperienceCurrent from './Views/ExperienceCurrent';
import FreeContentUseful from './Views/FreeContentUseful';
import SampleWork from './Views/SampleWork';
import AboutMe from './Views/AboutMe';
import ContactMe from './Views/ContactMe';
import CorporateTraining from './Views/Training';

import DemoApps from './components/SampleWorks/DemoApps/DemoApps';
import FeaturedWorks from './components/SampleWorks/FeaturedWorks';
import FeaturedTechDesignDocuments from './components/SampleWorks/FeaturedTechDesignDocuments';
import DiagramImages from './components/SampleWorks/DiagramImages/DiagramImages';

import TechnicalArticles from './components/FreeContent/TechnicalArticles';
import FreeTemplates from './components/FreeContent/FreeTemplates';
import TechnicalGuidelines from './components/FreeContent/TechnicalGuidelines';
import ContentSubjects from './components/FreeContent/ContentSubjects';
import Projects from './components/SampleWorks/Projects';

import Freelancer from './components/Resume/Freelancer';
import WorkExperience from './components/Resume/WorkExperience';
import AreasOfExpertise from './components/Resume/AreasOfExpertise';
import SkillMatrix from './components/Resume/SkillMatrix';
import Domains from './components/Resume/Domains';
import Education from './components/Resume/Education';
import Languages from './components/Resume/Languages';

import TrainingServices from './components/Training/TrainingServices';
import SampleTrainingContent from './components/Training/SampleTrainingContent';

import './AppRamesh.css';

class AppRamesh extends Component {

  render() {
    return (
      <div className="AppRamesh">
        <Header />

        <Routes>
          {/* Home page links */}
          <Route path="" Component={HomePage} />
          <Route path="expertise" Component={Expertise} />
          <Route path="skills" Component={SkillSet} />
          <Route path="about-me" Component={AboutMe} />
          <Route path="contact-me" Component={ContactMe} />

          <Route path="samples" Component={SampleWork} >
            <Route path="demos" Component={DemoApps} />
            <Route path="sample-code" Component={FeaturedWorks} />
            <Route path="tech-diagrams" Component={DiagramImages} />
            <Route path="design-documents" Component={FeaturedTechDesignDocuments} />
          </Route>

          <Route path="work-exp" Component={ExperienceCurrent}>
            <Route path="freelancer" Component={Freelancer} />
            <Route path="work" Component={WorkExperience} />
            <Route path="areas" Component={AreasOfExpertise} />
            <Route path="skills" Component={SkillSet} />
            <Route path="domains" Component={Domains} />
            <Route path="skills" Component={SkillMatrix} />
            <Route path="edu" Component={Education} />
            <Route path="lang" Component={Languages} />
          </Route>

          <Route path="training" Component={CorporateTraining}>
            <Route path="services" Component={TrainingServices} />
            <Route path="samples" Component={SampleTrainingContent} />
            <Route path="content-subjects" Component={ContentSubjects} />
          </Route>

          <Route path="free-content" Component={FreeContentUseful}>
            <Route path="templates" Component={FreeTemplates} />
            <Route path="guidlines" Component={TechnicalGuidelines} />
            <Route path="articles" Component={TechnicalArticles} />
            <Route path="content-subjects" Component={ContentSubjects} />
            <Route path="sample-code" Component={Projects} />

          </Route>

        </Routes>
        <ScrollToTop></ScrollToTop>
        <Footer />
      </div>
    );
  }
}

export default AppRamesh;