/* eslint-disable */
import 'jquery';

import { passiveSupport } from 'passive-events-support/src/utils'
passiveSupport({
  debug: false,
  events: ['touchstart', 'touchmove', 'touchend', 'mousewheel']
});

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";

import reportWebVitals from './Common/reportWebVitals.js';

import '@popperjs/core'; // Edit here

// Import CSS files
import './interio-assets/css/bootstrap.min.css';
import './interio-assets/css/font-awesome.min.css';
import './interio-assets/css/LineIcons.css';
import './interio-assets/css/animate.css';
import './interio-assets/css/aos.css';
import './interio-assets/css/slick.css';
import './interio-assets/css/default.css';
import './interio-assets/css/style.css';

// Import JavaScript files
import './interio-assets/js/vendor/modernizr-3.6.0.min.js';
import './interio-assets/js/bootstrap.min.js';
import './interio-assets/js/wow.min.js';
import './interio-assets/js/slick.min.js';
import './interio-assets/js/scrolling-nav.js';
import './interio-assets/js/jquery.easing.min.js';
import './interio-assets/js/main.js';

import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>

    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
