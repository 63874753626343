import React from 'react';
import './TableMatrix.css';

class TableMatrix extends React.Component {
    getNodeSubLists = (txtList) => {
        let subLists = [];
        let subList = [];
        let listItems = txtList;
        for (let i = 0; i < listItems.length; i++) {
            let listItem = listItems[i];
            let listItemText = listItem;

            if (listItemText !== '-') {
                subList.push(listItemText);
                if (i === listItems.length - 1) {
                    subLists.push(JSON.parse(JSON.stringify(subList)));
                }
            }
            else {
                subLists.push(JSON.parse(JSON.stringify(subList)));
                subList = [];
            }
        }

        return subLists;
    }

    render() {
        return (
            <div className='table-matrix'>
                <h4 className="titleStyle2">{this.props.data.description}</h4>     
                <div className='table-matrix-item'>
                    {this.props.data.children.map((node, index) => (
                        <div key={index} className='table-matrix-row-item'>
                            <div className='table-matrix-title'>{node.title}</div>
                            
                            <div className='table-matrix-texts'>
                                {node.list && node.list.length > 0 && this.getNodeSubLists(node.list).map((SubNode, index1) =>
                                    <div key={index1}>
                                        {index1 < this.getNodeSubLists(node.list).length - 1 ? (
                                            <div className='table-matrix-sub-texts-row'>
                                                {SubNode.map((text, index2) =>
                                                    <>
                                                        <span key={index2} className='table-matrix-text'>{text}</span> <span style={{ 'color': 'blue' }}> |</span>
                                                    </>
                                                )}
                                            </div>
                                        ) : (
                                            <div>
                                                {SubNode.map((text, index2) =>
                                                    <>
                                                        <span key={index2} className='table-matrix-text'>{text}</span><span style={{ 'color': 'blue' }}> |</span>
                                                    </>
                                                )}
                                            </div> // Added closing div tag here
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}

export default TableMatrix;