import React, { useState } from 'react';
import styled from "styled-components";

import './ExpertiseCard.css';

const ExpertiseCard = ({ service, display }) => {
  const [display2, setDisplay2] = useState(display);

  setDimensions();

  // Collect all items into a single array if display2 is false
  const allItems = [];
  service.children.forEach(child => {
    child.list.forEach(item => {
      if (item.display) {
        allItems.push(item);
      }
    });
  });

  const allChips = [];
  service.chipItems?.forEach(chip => {
    allChips.push(chip);
  });

  service.children.forEach(child => {
    if (child.chipItems) {
      child.chipItems.forEach(chipItem => {
        allChips.push(chipItem);
      });
    }
  });

  function toggleDisplay() {
    setDisplay2(!display2);
    console.log("toggleDisplay: display2 = ", display2);

    setDimensions();

  }

    // Function to update CSS custom properties
    function updateCardBeforeDimensions (height, width) {
      document.documentElement.style.setProperty('--card-before-height', height);
      document.documentElement.style.setProperty('--card-before-width', width);
    };
  
  function setDimensions() {
    if (display2) {
      updateCardBeforeDimensions('200%', '200%');
    } else {
      updateCardBeforeDimensions('500%', '400%');
    }
  }


  return (
    <StyledWrapper style={{ 'width': '100%', 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center' }}>
      <div className="card keep-it-center" >
        <div className="content">
          <div className="title-header">
            <p className="heading">{service.title} Services</p>
            <div className="switch-header">
              <label className="switch-label">Expand</label>
              <div className="toggle-wrapper">
                <input className="toggle-checkbox" type="checkbox" onChange={toggleDisplay} />
                <div className="toggle-container">
                  <div className="toggle-button">
                    <div className="toggle-button-circles-container">
                      <div className="toggle-button-circle"></div>
                      <div className="toggle-button-circle"></div>
                      <div className="toggle-button-circle"></div>
                      <div className="toggle-button-circle"></div>
                      <div className="toggle-button-circle"></div>
                      <div className="toggle-button-circle"></div>
                      <div className="toggle-button-circle"></div>
                      <div className="toggle-button-circle"></div>
                      <div className="toggle-button-circle"></div>
                      <div className="toggle-button-circle"></div>
                      <div className="toggle-button-circle"></div>
                      <div className="toggle-button-circle"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <p className="description">{service.description}</p>
          <div className={`para ${display2 ? 'expanded' : 'collapsed'}`}>
            <div className="column justify-content-left">
              {display2 ? (
                service.children.map((child, index) => (
                  <div key={index} className="mb-20">
                    <h4 className="services-title subTitleStyle">{child.title}</h4>
                    <ul>
                      {child.list.map((item, index) => (
                        <li key={index} className="itemStyle">{item.text}</li>
                      ))}
                    </ul>
                  </div>
                ))
              ) : (
                <ul>
                  {allItems.map((item, index) => (
                    <li key={index} className="itemStyle">{item.text}</li>
                  ))}
                </ul>
              )}

            </div>

          </div>
          <div className="content-types2">
            {allChips.map((item, index) => (
              <span key={index} className="content-type">{item}</span>
            ))}
          </div>
        </div>

      </div>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
.keep-it-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .card {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: fit-content;
  padding: 2px;
  border-radius: 24px;
  overflow: hidden;
  line-height: 1.6;
  background: linear-gradient(to right, #FFFFFF, , #0a3cff,#0a3cff);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 24px;
  padding: 34px;
  border-radius: 22px;
  color: #ffffff;
  overflow: hidden;
  background: #ffffff;
    background: linear-gradient(to right, #FFFFFF, #FFFFFF, #0a3cf0);
    
  //transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
}

.content .switch-label {
  z-index: 1;
  //transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
}

.content .heading {
  font-weight: 700;
  font-size: 30px;
  line-height: 1.3;
  z-index: 1;
  color: white;
  //transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
}

.content .description {
  font-weight: 500;
  font-size:16px;
  width: 100%;
  line-height: 1.2;
  z-index: 1;
  color: white;
  //transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
}

.content .para {
  z-index: 1;
  opacity: 0.8;
  width: 100%;
  font-size: 14px;
   overflow: hidden;
  //transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
}

 .content .para .collapsed {
    height: 0;
  }
  
 .content .para .expanded {
    height: auto; /* Or set a specific height if needed */
  }

.content .content-types2 {
z-index: 1;
  gap: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 10px;
  place-content: flex-start;
    //transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
}
  
.content .content-types2 .content-type {
z-index: 1;
  background-color: white;
  border 1px solid black;
  color: black;
  font-weight: 500;
  padding: 6px 10px 6px 10px;
  border-radius: 15px;
  font-size: 13px;
  text-wrap: nowrap;
  letter-spacing: -0.6px
    //transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
}

:root {
  --card-before-height: 350%;
  --card-before-width: 350%;
}

.card::before {
  content: "";
  position: absolute;
  height: var(--card-before-height);
  width: var(--card-before-width);
  border-radius: inherit;
  background: linear-gradient(to right, #0a3cff, #0a3cff);
  transform-origin: center;
  animation: moving 4.8s linear infinite paused;
    //transition: all 1.88s  cubic-bezier(0.23, 1, 0.32, 1); 
}

.card:hover::before {
  animation-play-state: running;
  z-index: -1;
  width: 80%;
}
  
.card:hover .content .heading,
.card:hover .content .description,
.card:hover .content .para {
  color: #000000;
}

.card:hover .content .content-types2 {
  color: #FFFFFF;
} 

.card:hover .content .content-types2 .content-type {
  background-color: black;
  color: white;
  border 1px solid white;
}

.card:hover {
  box-shadow: 0rem 6px 13px rgba(10, 60, 255, 0.1),
    0rem 24px 24px rgba(10, 60, 255, 0.09),
    0rem 55px 33px rgba(10, 60, 255, 0.05),
    0rem 97px 39px rgba(10, 60, 255, 0.01), 0rem 152px 43px rgba(10, 60, 255, 0);
  scale: 1.02;
  color: #000000;
}


@keyframes moving {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

`;

export default ExpertiseCard;
