import React, { Component } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import styled from 'styled-components';


const StyledLayout = styled.div`
  width: 100%;
  text-align: center;

  h1 {
    color: #ff69b4; /* Hot pink XX*/
    font-family: 'Pacifico', cursive; /* Fancy font */
    font-size: 64px;
    text-shadow: 1px 1px 1px  1px rgba(0, 0, 0, 0.5);
    animation: neon 1.5s ease-in-out infinite alternate;
  }

  h2 {
    font-family: 'Lobster', cursive; /* Fancy font */
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  }

  @keyframes neon {
    from {
      text-shadow: 0 0 10px #ff69b4, 0 0 12px #ff69b4, 0 0 13px #ff69b4, 0 0 16px #ff69b4, 0 0 20px #ff69b4, 0 0 24px #ff69b4, 0 0 30px #ff69b4;
    }
    to {
      text-shadow: 0 0 10px #ff1493, 0 0 12px #ff1493, 0 0 13px #ff1493, 0 0 16px #ff1493, 0 0 20px #ff1493, 0 0 24px #ff1493, 0 0 30px #ff1493;
    }
  }

  .nav-ramesh-link {
    position: absolute;
    text-align: center;
  }

  .ramesh-link {
    top: 180px;
    left: 100px;
  }

  .nav-uma-link {
    position: absolute;
    text-align: center;
  }

  .uma-link {
    bottom: 50px;
    right: 150px;
  }

  img {
    min-width: 10px;
    height: auto;
    transition: transform 0.8s ease-in-out;
  }

  img:hover {
    transform: scale(1.2);
  }

   .logo {
    position: absolute;
    animation: move 10s infinite alternate, rotate 20s infinite alternate;
    z-index: 1;
    background-color: transparent;
    border:4px solid yellow;
    box-shadow: 0 0 4px 12px #fff299;
    border-radius: 50%;
    padding: 10px;

  }

  .logo img {
  border-radius: 50%;
  border: 4px outset rgba(0, 0, 255, 0.7);
     box-shadow: 0px 0px 8px 12px rgba(0, 0, 255, 0.3);
}

  @keyframes move {
    0% {
      top: 0;
      left: 0;
      width: 40%;
       box-shadow: 0 0 2px 8px #fff299
    }
    25% {
      top: 50%;
      left: 25%;
          border: 6px solid yellow
    box-shadow: 0 0 12px 22px #fff299

    }
    50% {
      top: 75%;
      left: 50%;
                border: 4px solid yellow
    box-shadow: 0 0 8px 12px #fff299
    }
    75% {
      top: 25%;
      left: 75%;
                border: 6px solid yellow;
    box-shadow: 0 0 12px 18px #fff299
    }
    100% {
      top: 50%;
      left: 50%;
      width: 40%;
      border: 2px solid yellow;
      box-shadow: 0 0 2px 6px #fff299


    }
  }

   @keyframes rotate {
    0% {
          transform: rotate(0deg);
    }
    25% {
          transform: rotate(45deg);
          width: 3%;
                                              border: 6px solid rgba(0, 255, 0, .4);
    box-shadow: 6 6 5px 16px rgba(255, 215, 0, 0.8);

    }
    50% {
          transform: rotate(90deg);

    }
    75% {
          transform: rotate(-135deg);
          width: 20%;
                        border: 6px solid rgba(255, 255, 0, .4);
                        box-shadow: 0 0 1px 4px rgba(255, 255, 0, 0.8);
    }
    100% {
          transform: rotate(-180deg);
    }
  }

  /* Media queries for smaller screens */
  @media (max-width: 768px) {
    .logo img {
      width: 80px;
      height: auto;
    }

    h1 {
      font-size: 48px;
      margin-top: 20px;
      }

    .nav-ramesh-link {
    left:20px;
    top:150px;
}
    .ramesh-link img {
      width: 300px;
      height: auto;
        
    } 

        .nav-uma-link {
        bottom:20px;
        right:40px;
        }
  

    .uma-link img {
      width: 250px;
      height: auto;
      bottom:60px;
    }
  }

  @media (max-width: 480px) {
    .logo img {
      width: 60px;
      height: auto;
    }

        .nav-ramesh-link {
    top:200px;
}

    .ramesh-link img {
      width: 300px;
      height: auto;
      top:200px;
    }

 .nav-uma-link {
        bottom:5px;
        left:5px;
        }
    .uma-link img {
      width: 240px;
      height: auto;
    }
  }
`;

class AppLayout extends Component {
  render() {
    return (
      <StyledLayout>
        <div style={{ backgroundImage: 'url("images/bgs/umesh-bg2.jpg")', backgroundSize: 'cover', backgroundPosition: 'center', height: '100vh' }}>
          <h1>Umesh Works Pvt Ltd.</h1>
          <h2>A Startup Enterprise</h2>
          <div className="logo">
            <img src="images/umesh-logo-animated.gif" alt="Umesh Works" width="10px" height="auto" style={{ maxWidth: '128px', maxHeight: '128px' }} />
          </div>


          <NavLink to="ramesh" className="nav-ramesh-link ramesh-link">
            <img src="images/ramesh-pic.gif" alt="Ramesh Works" style={{ transform: 'rotate(-5deg)' }} />
            <h2 style={{ color: 'blue', transform: 'rotate(-5deg)', textAlign: 'center', marginLeft: '40px', marginTop: '-10px' }}>Ramesh Viswanathan</h2>
          </NavLink>

          <NavLink to="uma" className="nav-uma-link uma-link">
            <img src="images/uma-pic.gif" alt="Uma Works" style={{ transform: 'rotate(5deg)', borderRadius: '12px', border: '8px solid grey', boxShadow: 'inset 0 0 2px 3px lightgrey' }} />
            <h2 style={{
              color: 'orange', transform: 'rotate(5deg)', textAlign: 'center', marginLeft: '-40px', marginTop: '10px'
            }}>Uma Ramesh</h2>
          </NavLink>
        </div>

      </StyledLayout>
    );
  }
}


export default AppLayout;