import React from "react";
import styled from "styled-components";

const ServiceCard = ({ title, text }) => {
  return (
    <StyledWrapper>
      <div className="card">
        <div className="single-services text-center wow fadeInUp" style={{ 'borderRadius': '17px', 'width': '100%', 'height': '100%' }} data-wow-duration="1.5s" data-wow-delay="0.4s">
          <div className="services-icon mt-0">
            <img src="/images/software-eng.png" width="72"/>
          </div>
          <div className="services-content mt-2">
            <h4 className="services-title">{title}</h4>
            <p className="mt-20">{text}</p>
          </div>
{/*           <div className="services-btn mt-20">
            $60/hr
            </div>   */}
        </div>
      </div>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  .card {
  box-sizing: border-box;
  width: 480px;
  height: 440px;
  background: rgba(217, 217, 217, 0.58);
  border: 1px solid white;
  box-shadow: 12px 17px 51px rgba(0, 0, 0, 0.22);
  backdrop-filter: blur(6px);
  border-radius: 17px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  font-weight: bolder;
  color: black;
}

.card:hover {
  border: 1px solid black;
  transform: scale(1.05);
}

.card:active {
  transform: scale(0.95) rotateZ(1.7deg);
}
`;

export default ServiceCard;
