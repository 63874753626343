import React from 'react';

import ServiceCard from './ServiceCard';

import './Services.css';

function Services() {
    return (

        <section id="service" className="rameshv-container-col mt-20">

            <div className="col-lg-12">
                <div className="section-title text-center pb-15">
                    <h5 className="title">My Services</h5>
                    <h2 className="sub-title mb-15">I offer these core technical services</h2>
                </div>
            </div>


            <div className="row justify-content-center rameshv-container-row">
                <ServiceCard title="AI/ML/Gen AI - Early Adoption" text="Integrating artificial intelligence and machine learning into existing systems, from data preparation to model deployment. Covers custom ML solutions using frameworks like TensorFlow, PyTorch, and OpenAI API. Ensures AI components fit well within existing technology stacks." />
                <ServiceCard title="Expert Consulting Services" text="Addressing specific technical challenges by diving deep into architecture, development practices, or deployment strategies. Reviewing implementations, offering guidance on best practices, and optimizing workflows to solve technical problems effectively." />
                <ServiceCard title="Solution Architecting" text="Technical solutions designed to meet specific business requirements. Involves defining solution blueprints, integrating data flows, and selecting technology components to meet those needs. The goal is to ensure all architectural decisions are well-documented and in line with best practice" />
                <ServiceCard title="Web App/Mobile Development" text="Building responsive web and mobile applications using modern frameworks such as React, Angular, Vue, React Native, Ioncic, MAUI etc. Optimizing front-end user experiences, and ensuring compatibility with existing back-end APIs, across platforms, with a focus captivating visual designs, interactions & flows, clean architecture and seamless integration." />
                <ServiceCard title="Full Stack Development" text="From concept to deployment across front-end, back-end, database layers & other components with technologies such as Node.js, .NET, Java, Python, PHP. Emphasis on robust performance, scalability, fault tolernace, configurability, security & compliance, while leveraging best-in-class cloud services. A commitment to consistent development standards, testing, and integration." />
                <ServiceCard title="DevOps & CI/CD Automation" text="Setting up automated build, test, and deployment pipelines using tools like Jenkins, GitLab CI, and Azure DevOps. Infrastructure as code (IaC) using Terraform, and container orchestration with Kubernetes, to ensure consistent deployments and efficient release cycles." />
                <ServiceCard title="Security & Compliance Guidance" text="Integrating security throughout the software lifecycle, using practices like threat modeling, code analysis, and vulnerability management. Covers encryption, access management, and compliance with standards like GDPR and ISO 27001 to build secure, compliant systems." />
            </div>
        </section >
    );
}

export default Services;
