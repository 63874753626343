import React from 'react';
import './Testimonials.css';

function Testimonials() {
    return (
        <section id="testimonial" className="testimonial-area rameshv-container-col gray-bg pt-40">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="section-title text-center pb-20">
                            <h5 className="title mb-15">Testimonials</h5>
                            <h2 className="sub-title">Feedback from Current Clients</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shape shape-one">
                <img src="/interio-assets/images/testimonial/shape.png" alt="Testimonial" width="400"/>
            </div>
            <div className="shape shape-tow">
                <img src="/interio-assets/images/testimonial/shape.png" alt="Testimonial" width="450"/>
            </div>
            <div className="shape shape-three">
                <img src="/interio-assets/images/testimonial/shape.png" alt="Testimonial" width="450"/>
            </div>
            <div className="container">
                <div className="testimonial-bg bg_cover pt-10 pb-10 testimonial-image-container">
                    <div className="row">
                        <div className="col-xl-4 offset-xl-7 col-lg-5 offset-lg-6 col-md-8 offset-md-2 col-sm-10 offset-sm-1">
                            <div className="testimonial-active">
                                <div className="single-testimonial text-center">
                                    <div className="testimonial-image">
                                        <img src="/interio-assets/images/testimonial/t-1.jpg" alt="Testimonial" />
                                        <div className="quota">
                                            <i className="lni-quotation"></i>
                                        </div>
                                    </div>
                                    <div className="testimonial-content mt-20">
                                        <p>Lorem ipsum dolor sit amet, ectetur adipiscing elit. Phasellus vel erat ces, commodo lectus eu, finibus diam. m ipsum dolor sit amet, ectetur.</p>
                                        <h5 className="testimonial-name mt-15">Fajar</h5>
                                        <span className="sub-title">Random Customer</span>
                                    </div>
                                </div>
                                <div className="single-testimonial text-center">
                                    <div className="testimonial-image">
                                        <img src="/interio-assets/images/testimonial/t-2.jpg" alt="Testimonial" />
                                        <div className="quota">
                                            <i className="lni-quotation"></i>
                                        </div>
                                    </div>
                                    <div className="testimonial-content mt-20">
                                        <p>Lorem ipsum dolor sit amet, ectetur adipiscing elit. Phasellus vel erat ces, commodo lectus eu, finibus diam. m ipsum dolor sit amet, ectetur.</p>
                                        <h5 className="testimonial-name mt-15">Alina</h5>
                                        <span className="sub-title">Tesla Motors</span>
                                    </div>
                                </div>
                                <div className="single-testimonial text-center">
                                    <div className="testimonial-image">
                                        <img src="/interio-assets/images/testimonial/t-3.jpg" alt="Testimonial" />
                                        <div className="quota">
                                            <i className="lni-quotation"></i>
                                        </div>
                                    </div>
                                    <div className="testimonial-content mt-20">
                                        <p>Lorem ipsum dolor sit amet, ectetur adipiscing elit. Phasellus vel erat ces, commodo lectus eu, finibus diam. m ipsum dolor sit amet, ectetur.</p>
                                        <h5 className="testimonial-name mt-15">Celina</h5>
                                        <span className="sub-title">CEO, Alo</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Testimonials;
