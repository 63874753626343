const techDiagramsListJSON = {
    "data": [
        {
            "diagram": "claims middleware.png",
            "diagram_type": "UML Component Diagram",
            "description": "The integration between components of the 3rd party insurer system and Pharma eCOmmerce back-end is depicted",
            "link": "/files/tech-diagrams/claims/claims middleware.png"
        },
        {
            "diagram": "Inter VPC Kafka Arch Producer Side.jpg",
            "diagram_type": "Architecture Diagram",
            "description": "",
            "link": "/files/tech-diagrams/inter-vpc-kafka/Inter VPC Kafka Arch Producer Side.jpg"
        },
        {
            "diagram": "IS Consumer Sequence diagram.jpg",
            "diagram_type": "UML Sequence Diagram",
            "description": "",
            "link": "/files/tech-diagrams/inter-vpc-kafka/IS Consumer Sequence diagram.jpg"
        },
        {
            "diagram": "activity-diagram-Payment Service To Ledger Workflow.jpg",
            "diagram_type": "Swimlane Diagram",
            "description": "",
            "link": "/files/tech-diagrams/payments/activity-diagram-Payment Service To Ledger Workflow.jpg"
        },
        {
            "diagram": "Interaction Overview Diagram1.jpg",
            "diagram_type": "UML Interaction Overview Diagram",
            "description": "",
            "link": "/files/tech-diagrams/payments/Interaction Overview Diagram1.jpg"
        },
        {
            "diagram": "DevOps.jpg",
            "diagram_type": "DevOps Flow",
            "description": "",
            "link": "/files/tech-diagrams/general/DevOps.jpg"
        },
        {
            "diagram": "microservices arch.jpg",
            "diagram_type": "Microservices Architecture",
            "description": "",
            "link": "/files/tech-diagrams/general/microservices arch.jpg"
        },
        {
            "diagram": "State Machine Diagram1.jpg",
            "diagram_type": "State Machine Diagram",
            "description": "",
            "link": "/files/tech-diagrams/reqlaim/State Machine Diagram1.jpg"
        }
    ]
};

function getTechDiagramsListJSON() {
    return techDiagramsListJSON;
}

export default getTechDiagramsListJSON;