import { React, Component } from 'react';
import { FiArrowUpCircle } from 'react-icons/fi';

import './ScrollToTop.css'; // import the CSS file

class ScrollToTop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageAtTop: true
        }
    }

    componentDidMount() {
        const handleScroll = () => {
            if (window.scrollY === 0) {
                this.setState({ pageAtTop: true });
            } else {
                this.setState({ pageAtTop: false });
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }

    scrollUp = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    render() {
        return (
            <div>
                {this.state.pageAtTop ? null : (

                <button onClick={() => this.scrollUp()} className="scroll-to-top" >
                    <FiArrowUpCircle size={30} />
                </button>
                )}
            </div>
        );
    }
};

export default ScrollToTop;