import React, { Component } from 'react';
import TableMatrix from '../Common/Controls/TableMatrix';

import getSkillMatrixJSON from './data/SkillMatrixJSON';

import '../Common/AppBodyCommon.css'
import './Resume.css';
import './SkillMatrix.css';

class SkillMatrix extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matrixArray: this.getMatrixArray(getSkillMatrixJSON().data)
    }
  }

  getMatrixArray = (data) => {
    let matrixArray = [];

    for (let i = 0; i < data.length; i++) {
      let node = data[i];
      let category = node.category;
      let categoryFound = false;

      if (matrixArray.length === 0) {
        let matrixRow = {};
        matrixRow.category = category;
        matrixRow.description = node.description;

        let matrixRowChild = {};
        matrixRowChild.title = node.title;
        matrixRowChild.list = node.list;
        matrixRow.children = [];
        matrixRow.children.push(matrixRowChild);

        matrixArray.push(matrixRow);
      }
      else {
        for (let index1 = 0; index1 < matrixArray.length; index1++) {
          let matrixRowOld = matrixArray[index1];
          if (matrixRowOld.category === category) {
            let matrixRowChild = {};
            matrixRowChild.title = node.title;
            matrixRowChild.list = node.list;

            matrixRowOld.children.push(matrixRowChild);
            categoryFound = true;
            break;
          }
        }

        if (!categoryFound) {
          let matrixRowNew = {};
          matrixRowNew.category = category;
          matrixRowNew.description = node.description;

          let matrixRowChild = {};
          matrixRowChild.title = node.title;
          matrixRowChild.list = node.list;
          matrixRowNew.children = [];
          matrixRowNew.children.push(matrixRowChild);

          matrixArray.push(matrixRowNew);
        }
      }
    }

    return matrixArray;
  };

  render() {
    return (
      <div style={{ marginTop: '10px' }}>
        <div>
          {this.state.matrixArray.map((node, index) => (
            <div key={index} className='app-tab-item-container-item  wow fadeInUp'>
              <div className='skillmatrix-row '>
                <TableMatrix key={index} data={node} />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default SkillMatrix;

