import React, { Component } from 'react';
import BulletedTree from '../Common/Controls/BulletedTree';

import getAreasOfExpertiseJSON from './data/AreasOfExpertiseJSON';

import '../Common/AppBodyCommon.css'
import './Resume.css';
import './AreasOfExpertise.css';

class AreasOfExpertise extends Component {

  render() {
    const subListStyle = {
      fontSize: '15px',
      FontFace: 'Arial',
      borderRadius: '4px',
      width: 'fit-content',
      padding: '2px',
    }

    const titleStyle = {
      padding: '2px',
      paddingLeft: '5px',
      fontSize: '15px',
      color: 'deepblue',
      boxShadow: '1px 1px 2px 1px rgba(0, 0, 0, 0.5)',
      borderRadius: '4px',
      fontVariant: 'small-caps',
      backgroundColor: 'rgba(250, 250, 212, .7)',
      marginTop:'12px',
      marginBottom: '12px',
    }

    const subTitleStyle = {
      fontSize: '15px',
      FontFace: 'Arial',
      fontVariant: 'small-caps',
      padding: '2px 2px 2px 5px',
      boxShadow: 'rgba(0, 0, 0, 0.5) 1px 1px 2px 1px',
      borderRadius: '4px',
      marginTop:'12px',
      marginBottom: '12px',
      backgroundColor: 'rgba(100, 240, 170, 1)',
      width: 'fit-content',
        paddingRight: '6px',
    }

    return (
      <div className='app-tab-item-container'>
        <div className='app-tab-item-container-items zero-top'>
          <div className='app-tab-item-container-item'>
            <BulletedTree title="" data={getAreasOfExpertiseJSON().data} titleStyle={titleStyle} subTitleStyle={subTitleStyle} subListStyle={subListStyle} showParentBullet={false} />
          </div>
        </div>
      </div>
    );
  }
}

export default AreasOfExpertise;

