import React from 'react';
import './DemoApps.css';

function DemoApps() {
    return (
        <section id="project" className="rameshv-container-col mt-50">

            <div className="col-lg-12">
                <div className="section-title text-center pb-50">
                    <h5 className="title mb-15">Demo Apps</h5>
                    <h2 className="sub-title">Projects You May Love</h2>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row project-active project-container" >
                    <div className="col-lg-4">
                        <div className="single-project project-custom">
                        <a className="custom-project-title" href="/">LMS</a>
                            <div className="project-image">
                                <img src="/images/bgs/wip.png" alt="Project" />
                            </div>
                            
                            <div className="project-content">

                                <a className="project-title" href="/">SaaS Platform in Angular/ASP.NET/MySQL</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="single-project project-custom">
                        <a className="custom-project-title" href="/">Real-Time Communications</a>
                            <div className="project-image">
                                <img src="/images/bgs/wip.png" alt="Project" />
                            </div>
                          
                            <div className="project-content">
                                
                                <a className="project-title" href="/">RTC WebApp using WebRTC in Angular</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="single-project project-custom">
                        <a className="custom-project-title" href="/">Real-Time Communication on Mobile</a>
                            <div className="project-image">
                                <img src="/images/bgs/wip.png" alt="Project" />
                            </div>
                 
                            <div className="project-content">
         
                                <a className="project-title" href="/">RTC Mobile App using WebRTC in React-Native</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="single-project project-custom">
                        <a className="custom-project-title" href="/">WorkFlow Engine & Designer</a>
                            <div className="project-image">
                                <img src="/images/bgs/wip.png" alt="Project" />
                            </div>
         
                            <div className="project-content">
   
                                <a className="project-title" href="/">A Workflow engine using WexFlow.NET</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default DemoApps;
