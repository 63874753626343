const GuidlinesJSON = {
    "data": [
        {
            "Project": "Open AI",
            "description": "Demonstration of various OpenAI features and functionalities.",
            "SourceCode": [
                {
                    "ProgrammingLanguage": "Python",
                    "Link": "https://github.com/ramesh1972/python-ml-examples/tree/main/openai"
                },
                {
                    "ProgrammingLanguage": "Java",
                    "Link": "https://github.com/ramesh1972/java-ml-examples/tree/main/open-ai"
                },
                {
                    "ProgrammingLanguage": "C#",
                    "Link": "https://github.com/ramesh1972/dotnet-ml-examples/tree/main/Open-AI"
                }
            ],
            "Features": [
                "OpenAI Simple Chat",
                "OpenAI Embeddings",
                "OpenAI Functions",
                "OpenAI Fine Tuned Models",
                "OpenAI List Models",
                "OpenAI Stream Chat",
                "OpenAI Manage Token Size",
                "OpenAI Responsible AI",
                "OpenAI Area Statistics",
                "OpenAI Image Generation",
                "OpenAI Transcription",
                "OpenAI Chat Session"
            ]
        },
        {
            "Project": "Prompt Engineering",
            "description": "Prompt Engineering introduction and different techniques",
            "SourceCode": [
                {
                    "ProgrammingLanguage": "Python",
                    "Link": "https://github.com/ramesh1972/python-ml-examples/tree/main/prompt-eng"
                },
                {
                    "ProgrammingLanguage": "Java",
                    "Link": "https://github.com/ramesh1972/java-ml-examples/tree/main/open-ai"
                },
                {
                    "ProgrammingLanguage": "C#",
                    "Link": "https://github.com/ramesh1972/dotnet-ml-examples/tree/main/Open-AI"
                }
            ],
            "Features": [
                "Single Prompt",
                "Chat Completion",
                "Prompt Elements",
                "Effective Prompts",
                "Prompt Engineering Basic Prompt",
                "Prompt Engineering ChatBOT",
                "Prompt Engineering Prompt Elements",
                "Prompt Engineering Sentiment Analysis",
                "OpenAI Few Shot Prompting",
                "Chain of Thought Prompting",
                "General Knowledge Prompting",
                "Self Consistency Prompting Technique"
            ]
        },
        {
            "Project": "AI/ML",
            "description": " AI/ML project with showcasing most basic aspects of AI/ML",
            "SourceCode": [
                {
                    "ProgrammingLanguage": "Python",
                    "Link": "https://github.com/ramesh1972/POCs/tree/master/ai-ml-poc/python-ml-examples/ml"
                },
                {
                    "ProgrammingLanguage": "Java",
                    "Link": "https://github.com/ramesh1972/POCs/tree/master/ai-ml-poc/java-ml-examples/ml"
                },
                {
                    "ProgrammingLanguage": "C#",
                    "Link": "https://github.com/ramesh1972/POCs/tree/master/ai-ml-poc/dotnet-ml-examples/ML-AI"
                }
            ],
            "Features": [
                "ML Regression House Prices",
                "ML Taxi Fare Regression",
                "ML Classification Decimals Classification",
                "ML Classification Flowers Classification",
                "ML Image Classification Animal Images CLassification",
                "ML Image Classification of Cracked Floors",
                "ML Clustering Customers",
                "ML Clustering Weather Patterns",
                "ML Flower Images Classification",
                "ML Object Detection Captach Digits Recognizer",
                "ML Object Detection House Number Detection",
                "ML Neural Networks CIFAR",
                "ML SPAM Classification",
                "ML Intro Sentiment Analysis",
                "ML ETL Pipleline",
                "ML Gradio",
                "NLP CatalystNLP-LDA",
                "ML Model Deploy REST API",
                "ML NET ETL Pipeline",
                "ML NET VectorDB",
                "ML Vectors Store"
            ]
        },
        {
            "Project": "NLP",
            "description": "NLP project with showcasing most basic aspects of NLP",
            "SourceCode": [
                {
                    "ProgrammingLanguage": "Python",
                    "Link": "https://github.com/ramesh1972/python-ml-examples/tree/main/nlp"
                },
                {
                    "ProgrammingLanguage": "Java",
                    "Link": "https://github.com/ramesh1972/java-ml-examples/tree/main/nlp"
                },
                {
                    "ProgrammingLanguage": "C#",
                    "Link": "https://github.com/ramesh1972/dotnet-ml-examples/tree/main/NLP"
                }
            ],
            "Features": [
                "NLP Common Words Removal",
                "NLP Language Detection",
                "NLP Lemmatization",
                "NLP NER",
                "NLP NER Trainer Recognizer",
                "NLP OPENAI Sentiment Analyzer",
                "NLP Opinion Mining",
                "NLP Parser",
                "NLP Parts of Speech Tagger",
                "NLP Segmenter",
                "NLP Sentence Detector",
                "NLP Sentence Splitter",
                "NLP Stemming",
                "NLP Stop Words",
                "NLP Tokenization",
                "NLP Word Tokenizer",
                "NLP Word Embeddings",
                "NLP Sentiment Analysis Amazon Reviews"
            ]
        },
        {
            "Project": "LangChain",
            "description": "Sample LangChain project with different features and functionalities.",
            "SourceCode": [
                {
                    "ProgrammingLanguage": "Python",
                    "Link": "https://github.com/ramesh1972/python-ml-examples/tree/main/langchain"
                },
                {
                    "ProgrammingLanguage": "Java",
                    "Link": "https://github.com/ramesh1972/java-ml-examples/tree/main/langchain"
                }
            ],
            "Features": [
                "LangChain Chain With Document",
                "LangChain Chat With Document",
                "LangChain Chat with Memory",
                "LangChain Maths With LangChain Tools",
                "LangChain Prompt Templates",
                "LangChain Prompt with Template",
                "LangChain Service with Tools"
            ]
        },
        {
            "Project": "Hugging Face",
            "description": "Hugging Face project with different features and functionalities.",
            "SourceCode": [
                {
                    "ProgrammingLanguage": "Java",
                    "Link": "https://github.com/ramesh1972/java-ml-examples/tree/main/huggingface"
                }
            ],
            "Features": [
                "ML NLP HuggigFace NLP",
                "ML NLP HuggigFace NLP API",
                "ML NLP Stanford Core NLP"
            ]
        },
        {
            "Project": "Angular NgRx Store Example",
            "description": "A sample demontrating how to use store and state management in Angular.",
            "SourceCode": [
                {
                    "ProgrammingLanguage": "Angular, .NET",
                    "Link": "https://github.com/ramesh1972/angular-ngrx-sample"
                }
            ],
            "Features": [
                "Angular Store using NgRX",
                "2 Way State Binding using RxJS",
                "Computed State using NgRx State & RxJS",
                "Generic API Response handling using NgRx",
            ]
        },
        {
            "Project": "Calling Bing AI Chat API",
            "description": "A simple example demontrating how to use Bing Chat APIs.",
            "SourceCode": [
                {
                    "ProgrammingLanguage": "Javascript",
                    "Link": "https://github.com/ramesh1972/Bing-Chat-AI-Simple-Example"
                }
            ],
            "Features": [
                "Calling Bing AI Chat API"
            ]
        },
        {
            "Project": "GPT Engineer Example",
            "description": "A demonstration of how to use GPT Engineer to generate complete project from a one line idea",
            "SourceCode": [
                {
                    "ProgrammingLanguage": "Python",
                    "Link": "https://github.com/ramesh1972/GPTEngineer-example"
                }
            ],
            "Features": [
                "Creating a complete project from a one line idea using GPT Engineer",
                "Refining the generated project using GPT Engineer"
            ]
        }
    ]
}
          

function getGuidelinesJSON() {
    return GuidlinesJSON;
}

export default getGuidelinesJSON;