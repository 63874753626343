import React from "react"
import './AboutMe.css'
import About from "../components/HomePage/About/About"

export default class AboutMe extends React.Component {
    render() {
        return (
            <div id="experience-current" className="exp-container">
                <section id="contact1" className="services-area pt-175">
                    <About />
                </section>
            </div>
        )
    }
}