import React, { Component } from 'react';

import BulletedTree from '../Common/Controls/BulletedTree';

import getContentSamplesJSON from './data/contentSamplesJSON';

import './SampleTrainingContent.css';

class SampleTrainingContent extends Component {
  subListStyle2 = { paddingTop: '2px', paddingLeft: '2px'};

  filterJSON = [];

 
  titleStyle = {
    fontSize: '16px',
    //boxShadow: '2px 2px 2px 0px rgba(33, 150, 243, 0.8)',
    FontFace: 'Arial',
    padding: '2px 2px 2px 5px',
    boxShadow: 'rgba(0, 0, 0, 0.5) 1px 1px 2px 1px',
    borderRadius: '4px',
    fontVariant: 'small-caps',
    backgroundColor: 'rgba(250, 250, 212, 0.7)',
      paddingRight: '6px',
  paddingBottom: '4px'
}

subTitleStyle = {
  fontSize: '15px',
  FontFace: 'Arial',
  fontVariant: 'small-caps',
  padding: '2px 2px 2px 5px',
  boxShadow: 'rgba(0, 0, 0, 0.5) 1px 1px 2px 1px',
  borderRadius: '4px',
  marginTop:'8px',
  backgroundColor: 'rgba(250, 250, 60, 1)',
  width: 'fit-content',
    paddingRight: '6px',
  paddingBottom: '4px'
}

  subListStyle = { fontSize: '15px', paddingTop: '2px', paddingLeft: '2px'};

  subListStyle2 = { paddingTop: '2px', paddingLeft: '2px'};
  
  render() {
    return (
      <section id="skillsset2" className="services-area pt-20 gray-bg">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-20">
                        <div className="section-title text-center">
                            <h5 className="sub-title mb-15">View Sample Training Material of Different Kinds</h5>
                        </div>
                    </div>
                </div>
            </div>

            <div>
            <BulletedTree title="" data={getContentSamplesJSON().data} anchorInNewWindow={false} filter={this.filterJSON} titleStyle={this.titleStyle} subListStyle={this.subListStyle2} showParentBullet={false}/>
            </div>
        </section>
    );
  }
};

export default SampleTrainingContent;

