import React from "react"

import Resume from "../components/Resume/Resume"
import Contact from "../components/HomePage/Contact/Contact"

import './ContactMe.css'

export default class ContactMe extends React.Component {
    render() {
        return (
            <div id="experience-current" className="exp-container">
                <section id="contact1" className="services-area pt-175 mt-50">
                    <Contact />
                </section>
                <section id="contact12" className="services-area pt-50">

                        <div className="justify-content-center align-items-center" style={{ 'fontSize': '40px', 'display': 'grid', 'backgroundColor': 'white', 'border': '1px solid grey', 'padding': '20px' }}>
                            <div className="section-title text-center mb-20" style={{'width': '100%'}}>
                                <h5 className="title mb-15">Call Me</h5>
                                <div className="sub-title row align-items-center justify-content-center">
                                    <div className="info-icon mr-10 mt-10" style={{ 'fontSize': '36px' }}>
                                        <i className="lni-phone"></i>
                                    </div>
                                    <div className="info-content">
                                        <a style={{ 'fontSize': '36px' }}>+91 875 449 1947</a>
                                    </div>
                                </div>

                            </div>
                            <div className="section-title text-center mb-0 pb-0" style={{'width': '100%'}}>

                                <h5 className="title mb-15">Email Me</h5>
                                <div className="sub-title row align-items-center justify-content-center">
                                    <div className="info-icon mr-10 mt-10" style={{ 'fontSize': '44px' }}>
                                        <i className="lni-envelope"></i>
                                    </div>
                                    <div className="info-content">
                                        <a style={{ 'fontSize': '34px' }}>contact@rameshv.me</a>
                                    </div>
                                </div>
                            </div>
                            </div>

                </section>
            </div>
        )
    }
}