import React from 'react';

import '../../../Common/Utils/logging';

import getTechDiagramsListJSON from '../data/techDiagramsList';

import DiagramCardFlat from './DiagramCardFlat';
import './DiagramImages.css'


var techDiagramsJSON = getTechDiagramsListJSON().data;

function DiagramImages() {

    var images = techDiagramsJSON.map((image, index) => {
        console.debug("the following is the original image link: ", image.link);
        return {
            link: image.link,
            description: image.description,
            diagram_type: image.diagram_type
        };
    });

    return (

        <section id="diagrams" className="rameshv-container-col mt-20">

            <div className="col-lg-12">
                <div className="section-title text-center pb-50">
                    <h5 className="title mb-15">Featured Technical Diagrams</h5>
                    <h2 className="sub-title mb-10">Showcasing attention to Detail</h2>
                    <span className="highlight-text"><strong>*</strong> Click on an image to view in fullscreen</span>
                </div>
            </div>

            <div className='rameshv-container-row'>
                {images.map((image, index) => (
                    <div key={index}>
                        <DiagramCardFlat title={image.diagram_type} link={image.link} />
                    </div>
                ))}
            </div>
        </section>
    );
}

export default DiagramImages;
