import React, { Component } from 'react';

import getProjectsJSON from './data/ProjectsJSON';

import '../Common/AppBodyCommon.css';
import './Projects.css';

class Projects extends Component {

    node = getProjectsJSON().data;

    render() {
        return (
            <div className='app-tab-item-child-container'>
                {this.node.map((project, index) => (
                    <div key={index} className='projects-bulleted-link-tree'>
                        <div className="project-title">
                            <span className='projects-bulleted-link-tree-node-title'>Project: </span>
                            <span className='projects-bulleted-link-tree-node-text'>{project.Project}</span>
                        </div>

                        <div>
                            <span className='projects-bulleted-link-tree-node-title'>Description: </span>
                            <span className='projects-bulleted-link-tree-node-text'>{project.description}</span>
                        </div>
                        <div className='projects-bulleted-link-tree-node-title'>Features</div>
                        <div className="projects-bulleted-link-list" style={{ fontSize: '14px' }}>
                            <span style={{ 'color': 'blue' }}> |</span>
                            {project.Features && project.Features.length > 0 &&
                                project.Features.map((feature, indexFeature) => (
                                    <span key={indexFeature}>
                                        <span>&nbsp;{feature}&nbsp;</span> <span style={{ 'color': 'blue', fontSize: '16px' }}> |</span>
                                    </span>
                                ))}
                        </div>



                        {
                            project.SourceCode && project.SourceCode.length > 0 && (
                                <div style={{ width: '100%', marginBottom: '30px' }}>
                                    <div className='projects-bulleted-link-tree-node-title'>Source Code</div>
                                    <div className='projects-bulleted-link-list'>
                                        <table>
                                            {project.SourceCode.map((source, indexSource) => (
                                                <tr>
                                                    <td key={indexSource} className='projects-bulleted-link-tree-list-item-text'>
                                                        <strong>{source.ProgrammingLanguage}</strong>
                                                    </td>
                                                    <td>
                                                        <a href={source.Link} target='_blank' rel='noopener noreferrer'>{source.Link}</a>
                                                    </td>
                                                </tr>
                                            ))}
                                        </table>
                                    </div>
                                </div>
                            )
                        }
                    </div >
                ))
                }
            </div >
        )
    }
}

export default Projects;

