import React from 'react';
import { NavLink } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const RadioTabsDynamic = ({ tabs }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <StyledWrapper>
      <div className="container">
        <div className="radio-tile-group">
          {tabs.map((tab, index) => (

            <NavLink
              to={tab.path}
              key={index}
              isActive={tab.selected}
              className={({ isActive }) => `input-container ${isActive || (location.pathname === tab.path && tab.selected) ? 'active' : ''}`}
            >
              <div className="input-container">
                <input id={tab.id} className="radio-button" type="radio" name="radio" checked={tab.selected}/>
                <div className="radio-tile">
                  <div className="icon" dangerouslySetInnerHTML={{ __html: tab.iconSvg }} />
                  <label htmlFor={tab.id} className="radio-tile-label">{tab.label}</label>
                </div>
              </div>
            </NavLink>
          ))}
        </div>
      </div>
    </StyledWrapper >
  );
};

const StyledWrapper = styled.div`
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .radio-tile-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .radio-tile-group .input-container {
    position: relative;
    height: 100px;
    width: 100px;
    margin: 0.5rem;
  }

  .radio-tile-group .input-container .radio-button {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    cursor: pointer;
  }

  .radio-tile-group .input-container .radio-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: 2px solid #079ad9;
    border-radius: 5px;
    padding: 1rem;
    transition: transform 300ms ease;
  }

  .radio-tile-group .input-container .icon svg {
    fill: #079ad9;
    width: 2rem;
    height: 2rem;
  }

  .radio-tile-group .input-container .radio-tile-label {
    text-align: center;
    margin-top: 0.5rem;
    font-size: 0.65rem;
    font-weight: 600;
    text-transform: uppercase;
    text-wrap: wrap;
    letter-spacing: 1px;
    color: #079ad9;
  }

    .radio-tile-group .input-container.active {
    color: white;
        text-wrap: wrap;  
    transform: scale(1.1, 1.1); /* Move 10px right and 10px down */
  }

    .radio-tile-group .input-container.active .radio-tile {
    color: white;
    background-color: #079ad9;

    padding: 0rem;
  }


  .radio-tile-group .input-container.active .icon svg {
    fill: white;
    background-color: #079ad9;
  }

  .radio-tile-group .input-container.active .radio-tile-label {
    color: white;
    background-color: #079ad9;
  }
`;


export default RadioTabsDynamic;