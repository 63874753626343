import React from 'react';
import './VideoView.css';

const VideoView = ({ videoSrc, title }) => {
  return (
    <div className="video-container">
      <div className="video-wrapper">
        <video controls className="video-element">
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default VideoView;