import React from "react";
import styled from "styled-components";

import "./DiagramCardFlat.css";
const handleImageClick = (event) => {
  const imageElement = event.target;
  if (imageElement.requestFullscreen) {
    imageElement.requestFullscreen();
  } else if (imageElement.mozRequestFullScreen) { // Firefox
    imageElement.mozRequestFullScreen();
  } else if (imageElement.webkitRequestFullscreen) { // Chrome, Safari and Opera
    imageElement.webkitRequestFullscreen();
  } else if (imageElement.msRequestFullscreen) { // IE/Edge
    imageElement.msRequestFullscreen();
  }
};

function handleButtonClick(link) {
  console.log("pn button click", link);
  // open link in fullscreen
  if (link.requestFullscreen) {
    link.requestFullscreen();
  } else if (link.mozRequestFullScreen) { // Firefox
    link.mozRequestFullScreen();
  } else if (link.webkitRequestFullscreen) { // Chrome, Safari and Opera
    link.webkitRequestFullscreen();
  } else if (link.msRequestFullscreen) { // IE/Edge
    link.msRequestFullscreen();
  }
  else {
    // open link in new tab
    window.open(link);
  }
}


const DiagramCardFlat = ({ title, link }) => {
  return (
    <StyledWrapper>
      <article className="card">
        <section className="card__hero">
          <img src={link} alt="Diagram" className="image-viewer" height="390px" width="420px" style={{'border': '1px solid lightgrey'}} onClick={handleImageClick} />
        </section>

        <footer className="card__footer">
          <div className="card__job-summary">
            <div className="card__job-icon">
              <img src="/images/uml-thumbnail-removebg-preview.png" alt="Tech Stack" width="32px" height="32px" />
            </div>
            <div className="card__job">
              <p className="card__job-title sample-diagram-topic">{title}</p>
            </div>
          </div>

          <button className="card__btn">View</button>
        </footer>
      </article>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  .card {
  margin: auto;
  width: 410px;
  height: 450px;
  background-color: lightgoldenrodyellow;
  border-radius: 1rem;
  padding: 0.2rem;
  color: #141417;
  box-shadow: 1px 1px 20px 2px rgba(0, 0, 0, 0.1);
}
.card__hero {
  background-color: lightgoldenrodyellow;
  border-radius: 0.5rem 0.5rem 0 0;
  padding: .22rem;
  font-size: 0.875rem;
}
.card__hero .card__job-title {
  margin: 2rem 0;
  font-size: 2rem;
  font-weight: 700;
  padding-right: 2rem;
}
.card__hero-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 1rem;
  font-weight: 700;
}
.card__footer {
  display: flex;
  justify-content: flex-start;
  align-items: start;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 0.75rem;
  row-gap: 1rem;
  font-weight: 700;
  font-size: 0.875rem;
  justify-content: space-between;
  align-items: start;
  justify-items: middle;
  align-content: middle ;
}
@media (min-width: 340px) {
  .card__footer {
    flex-direction: row;
    align-items: strat;
    justify-content: space-between;
    gap: 1rem;

    align-content: center;
    justify-items: center;
}
  }
}
.card__job-summary {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0.75rem;
}
.card__btn {
  width: 100%;
  font-weight: 400;
  border: none;
  display: block;
  cursor: pointer;
  text-align: center;
  padding: 0.5rem 1.25rem;
  border-radius: 1rem;
  background-color: #141417;
  color: #fff;
  font-size: 1rem;
}
@media (min-width: 340px) {
  .card__btn {
    width: max-content;
  }
}

`;

export default DiagramCardFlat;
