import React, { Component } from 'react';

import Select from 'react-select';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';

import ScrollToTop from '../Common/ScrollToTop/ScrollToTop';
import SwitchControl from '../Common/Controls/SwirchControl';

import '../Common/AppBodyCommon.css';
import './ContentSubjects.css';

class ContentSubjects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subjects_count: 0,
            categories_count: 0,
            topics_count: 0,
            subjects: [],
            topics: [],
            selectedSubject: null,
            selectedCategory: null,
            subjectsSelect: [],
            categoriesSelect: [],
            isLoading: true
        }
    }

    componentDidMount() {
        this.initSubjects();

    }

    fetchSubjectsJSONFile = async (url) => {

        let response = await fetch(url, {
            headers: {
                accept: 'application/json'
            }
        });

        let result = await response.json();

        this.setState({ isLoading: false });
        return result;
    }

    fetchCategoryJSONFile = async (url) => {
        this.setState({ isLoading: true });
        // add a leading / if not exists already
        if (url !== null && url !== undefined && url.length > 0 && url.charAt(0) !== '/') {
            url = '/' + url;
        }

        let response = await fetch(url)
        response = await response.json();
        this.setState({ isLoading: false });
        return response.topics;
    }


    initSubjects = () => {

        // fetch the below json and covert to json object
        const fetchData = async () => {
            var URL = '/data/content/contentSubjects.json';

            let subs = await this.fetchSubjectsJSONFile(URL);
            this.getSubjects(subs);
        };

        fetchData();
    }

    handleSubjectSelectChange = (selectedOption) => {
        this.setState({ selectedSubject: selectedOption });
        this.setState({ selectedCategory: null });

        console.log(`Option selected:`, this.state.selectedSubject);

        let categoriesTemp = [];
        this.state.subjects.forEach(element => {
            console.log(`element.subject:`, element.subject);

            if (element.subject === selectedOption.value) {
                console.log(`element.categories:`, element.categories);
                element.categories.forEach(elementc => {
                    console.log(`elementc.category:`, elementc.category);
                    categoriesTemp.push({ value: elementc.category, label: elementc.category });
                });
            }
        });

        categoriesTemp.reverse();
        this.setState({ categoriesSelect: categoriesTemp });
    };

    handleCategorySelectChange = (selectedOption) => {

        this.setState({ selectedCategory: selectedOption });
        //console.log(`Option selected category:`, this.state.selectedCategory.value);

        //let topicsTemp = [];
        var topicsJSONFile = "";
        this.state.subjects.forEach(element => {
            if (element.subject === this.state.selectedSubject.value) {
                element.categories.forEach(elementc => {
                    if (elementc.category === selectedOption.value) {
                        topicsJSONFile = elementc.fileName;
                    }
                });
            }
        });

        var topicNum = 1;
        const fetchTopics = async () => {
            let topics = await this.fetchCategoryJSONFile(topicsJSONFile);
            topics.forEach(element => {
                element.topic_no = topicNum++;
            });

            this.setState({ topics: topics });
        };

        fetchTopics();


    };

    getSubjects = (subs) => {
        let subjectsTemp = [];

        this.setState({ subjects: subs.subjects });

        let cat_count = 0;
        for (let i = 0; i < subs.subjects.length; i++) {
            subjectsTemp.push({ value: subs.subjects[i].subject, label: subs.subjects[i].subject });
            cat_count = cat_count + subs.subjects[i].categories.length;
        }

        this.setState({ subjects_count: subs.subjects.length });
        this.setState({ categories_count: cat_count });
        this.setState({ topics_count: subs.topics_count });

        // reverse the array
        subjectsTemp.reverse();
        this.setState({ subjectsSelect: subjectsTemp });

        let selSub = subs.subjects[0].subject;
        this.setState({ selectedSubject: selSub });

        let selCat = subs.subjects[0].categories[0].category;
        this.setState({ selectedCategory: selCat });
    }

    render() {
        const customStyles = {
            control: (provided, state) => ({
                ...provided,
                fontSize: '14px;',
                backgroundColor: 'rgb(33, 150, 243)',
                color: 'black',
                width: '240px',
                height: '30px',
            }),
            placeholder: (provided, state) => ({
                ...provided,
                color: 'black',
                marginTop: '0px'
            }),
            option: (provided, state) => ({
                ...provided,
                fontSize: '12px;',
            }),
            valueContainer: (provided, state) => ({
                ...provided,
                color: 'black',
                marginTop: '-2px'
            }),
        }

        return (
            <>
            <section id="skillsset2" className="services-area pt-20 gray-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-20">
                            <div className="section-title text-center">
                            <h5 className="sub-title mb-15">Explore a treasure trove of knowledge!</h5>
                            </div>
                         </div>
                    </div>
                </div>
            </section>
      


                <div className='content-subject-header'>
                    <span>Deep Dive into  <strong style={{ color: 'blue' }}>{this.state.topics_count} T</strong>opics spanning across <strong style={{ color: 'blue' }}>{this.state.categories_count} C</strong>ategories across <strong style={{ color: 'blue' }}>{this.state.subjects_count} S</strong>ubjects in the vast realm of software.<br /><br /> Access code snippets and insightful content galore! for each Topic.</span>
                </div>

                <div className='content-subject-dropdowns'>
                    <Select options={this.state.subjectsSelect} className='subjects-dropdown'
                        onChange={this.handleSubjectSelectChange}
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'}  // Position menu absolute to the parent container
                        menuShouldBlockScroll={true}  // Prevents scroll in the container
                        styles={customStyles}
                        placeholder="Select a Subject"
                        isSearchable={false}
                    />
                    <Select options={this.state.categoriesSelect} className='categories-dropdown'
                        onChange={this.handleCategorySelectChange}
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'}  // Position menu absolute to the parent container
                        menuShouldBlockScroll={true}  // Prevents scroll in the container
                        styles={customStyles}
                        placeholder="Select a Category"
                        value={this.state.selectedCategory}
                        isSearchable={false}
                    />
                </div>
                {this.state.isLoading ? (<div style={{ marginTop: '10px', width:'100%' }}>Loading Topics...</div>) :
                    this.state.topics.length > 0 &&
                    <ContentTopic topics={this.state.topics} wrapCodeLines={this.state.wrapCodeLines} />
                }
            </>
        )
    }
}

export default ContentSubjects;

class ContentTopic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            node: this.props.topics,
            wrapCodeLines: false
        }
    }

    componentDidMount() {
        this.setState({ node: this.props.topics });
    }

    getSplitCodeLines = (code) => {
        return code.split('\n').map((item, i) => {
            return <span key={i}>{item}<br /></span>;
        });
    }

    render() {

        let node = this.props.topics;

        return (
            <div className='app-tab-item-child-container'>
                <div style={{ paddingTop: '8px' }}>
                    <SwitchControl label='Wrap Code Lines' checked={this.state.wrapCodeLines} onChange={() => { this.setState({ wrapCodeLines: !this.state.wrapCodeLines }) }} />
                </div>

                {node.map((topic, index) => (
                    <div key={index} className='content-topic-bulleted-link-tree'>
                        <div className="content-topic-title-header">
                            <span className='content-topic-number'>{topic.topic_no})&nbsp;</span>
                            <div>
                                <strong>Topic:&nbsp;</strong>
                                <span className='content-topic-bulleted-link-tree-node-title-text'>{topic.topic}</span>
                                {topic.contents && topic.contents.length > 0 && (
                                    <ul className='content-topic-bulleted-link-list'>
                                        {topic.contents.map((content, indext) => (
                                            <div key={indext} className='content-topic-item'>
                                                <li style={{width:'100%'}}>
                                                    <span className='content-topic-bulleted-link-tree-list-item-text'>{content.text}</span>
                                                </li>
                                                {content.codeSnippet && (
                                                    <SyntaxHighlighter wrapLines={this.state.wrapCodeLines} wrapLongLines={this.state.wrapCodeLines} language={content.codeLanguage} className='code-snippet'>
                                                        {content.codeSnippet}
                                                    </SyntaxHighlighter>
                                                )}
                                            </div>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
                <ScrollToTop />
            </div>
        )
    }
}