import React from "react"
import FreeContent from "../components/FreeContent/FreeContent"

import './FreeContentUseful.css'
import TechContentCreationService from "../components/Training/TechContentCreationService"

export default class CorporateTraining extends React.Component {
    render() {
        return (
            <section id="free-content-useful" className="col-lg-12 pt-175 gray-bg align-iterms-start rameshv-container-col">
                <div className="container">
                    <div className="row justify-content-left">
                        <div className="col-lg-12">
                            <div className="section-title text-left pb-20">
                                <h5 className="title mb-15">Corporate Training Services</h5>
                            </div>
                        </div>
                    </div>
                </div>

                <TechContentCreationService />
            </section>

        )
    }
}