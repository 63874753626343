import React from "react";

import styled from "styled-components";

import VideoView from "../../Common/Controls/VideoView";

const VideoCard = () => {
  return (
    <StyledWrapper>
      <div className="container">
        <div className="card_box">
          <span />
          <VideoView videoSrc="/files/videos/ramesh-viswanathan-intro.mp4" />
        </div>
      </div>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  .container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card_box {
  width: 400px;
  height: 280px;
  border-radius: 20px;
  background: linear-gradient(170deg, rgba(58, 56, 56, 0.623) 0%, rgb(31, 31, 31) 100%);
  position: relative;
  box-shadow: 0 25px 50px rgba(0,0,0,0.55);
  cursor: pointer;
  transition: all .3s;
}

.card_box:hover {
  transform: scale(0.9);
}

.card_box span {
  position: absolute;
  overflow: hidden;
  width: 150px;
  height: 150px;
  top: -10px;
  left: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card_box span::before {
  content: 'My Video Intro';
  position: absolute;
  width: 150%;
  height: 40px;
  background-image: linear-gradient(45deg, #FF512F 0%, #809819   51%, #FF512F  100%);
  transform: rotate(-45deg) translateY(-20px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  box-shadow: 0 5px 10px rgba(0,0,0,0.23);
  z-index: 100;
}

.card_box span::after {
  content: '';
  position: absolute;
  width: 10px;
  bottom: 0;
  left: 0;
  height: 10px;
  z-index: -1;
  box-shadow: 140px -140px #cc3f47;
  background-image: linear-gradient(45deg, #FF512F 0%, #809819   51%, #FF512F  100%);
}
`;

export default VideoCard;
