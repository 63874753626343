import React, { Component } from 'react';

import PageTop from '../components/HomePage/PageTop/PageTop';
import About from '../components/HomePage/About/About';
import Services from '../components/HomePage/Services/Services';
import Testimonials from '../components/HomePage/Testimonials/Testimonials';
import Contact from '../components/HomePage/Contact/Contact';

import './HomePage.css';
import { Outlet } from 'react-router-dom';

export class HomePage extends Component {

  render() {
    return (
      <div id="homepage" className="col-lg-12 rameshv-container-main">
        <PageTop />
        <Services />
        <Testimonials />
        <Contact />
      </div>
    );
  }
}

export default HomePage;