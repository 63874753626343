import React, { Component } from 'react';

import ScrollToTop from '../components/Common/ScrollToTop/ScrollToTop';

import getServiceOfferings from './data/service-offerings';

import './Expertise.css';
import ExpertiseCard from './ExpertiseCard';

class Expertise extends React.Component {
  constructor(props) {
    super(props);

  }

  state = {
    services: []
  };

  componentDidMount() {
    this.setState({ services: getServiceOfferings().data });
  }

  render() {
    return (
      <div id="expertise" className="expertise-container">
        <section id="service" className="services-area col-lg-12 pt-175 mt-50 gray-bg" style={{ 'alignSelf': 'start' }}>
          <div className="container">
            <div className="row justify-content-left">
              <div className="col-lg-12">
                <div className="section-title pb-20" style={{ 'width': '100%' }}>
                  <h5 className="sub-title mb-15">My Services</h5>
                  <h2 className="title" style={{ 'width': '100%' }}>Discover My Expertise</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="column expertise-container">
            {this.state.services.map((service, index) => (
              <div key={index} className="text-left">
                <ExpertiseCard service={service} display={false} />
              </div>
            ))}
          </div>
        </section>
        <ScrollToTop />
      </div>
    )
  }
}

export default Expertise;