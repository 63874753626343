import React from 'react';

import Projects from './Projects';

const FeaturedWorks = () => {
  return (
    <section id="work" className="services-area pt-20 gray-bg">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-20">
            <div className="section-title text-center pb-50">
              <h5 className="sub-title mb-15">Featured Code</h5>
              <h5 className="title">POCs in AI/ML/NLP/Gen AI</h5>
            </div>
          </div>
        </div>
      </div>

      <Projects />
    </section>
  );
};

export default FeaturedWorks;