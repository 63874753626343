const contentSamplesJSON = {
    "data": [
        {
            "title": "Courses",
            "list": [
                {
                    "title": "GenAI_daywise_Coverage_Prompt Engineering_V2-10th-Oct-Ramesh.xlsx",
                    "link": "./files/sample-content/course/GenAI_daywise_Coverage_Prompt Engineering_V2-10th-Oct-Ramesh.xlsx",
                    "description": "Daywise coverage plan for Prompt Engineering under GenAI, version 2, dated October 10th by Ramesh."
                }
            ]
        },
        {
            "title": "Decks",
            "list": [
                {
                    "title": "AI Primer for Beginners.pdf",
                    "link": "./files/sample-content/decks/AI Primer for Beginners.pdf",
                    "description": "Presentation deck covering an AI primer for beginners."
                },
                {
                    "title": "Building NLP Solutions with AI.pdf",
                    "link": "./files/sample-content/decks/Building NLP Solutions with AI.pdf",
                    "description": "Presentation deck focusing on building NLP (Natural Language Processing) solutions with AI."
                },
                {
                    "title": "Prompt Engineering - Short.pdf",
                    "link": "./files/sample-content/decks/Prompt Engineering - Short.pdf",
                    "description": "Short presentation deck related to Prompt Engineering."
                }
            ]
        },
        {
            "title": "Videos",
            "list": [
                {
                    "title": "Gen AI for ReactJS.mp4",
                    "link": "./files/sample-content/videos/Gen AI for ReactJS.mp4",
                    "description": "Video focusing on Gen AI for ReactJS."
                },
                {
                    "title": "Gen AI Code Companion for NodeJS.mp4",
                    "link": "./files/sample-content/videos/Gen AI Code Companion for NodeJS.mp4",
                    "description": "Video regarding Gen AI code companion for NodeJS."
                },
                {
                    "title": "Bing Chat AI and Prompt Engineering-V2.mp4",
                    "link": "./files/sample-content/videos/Bing Chat AI and Prompt Engineering-V2.mp4",
                    "description": "Video titled 'Bing Chat AI and Prompt Engineering', version 2."
                }
            ]
        },
        {
            "title": "Guided Labs",
            "list": [
                {
                    "title": "Lab_04_Prompt_Engineering_Sentiment Analysis.docx",
                    "link": "./files/sample-content/vlabs/Lab_04_Prompt_Engineering_Sentiment Analysis.docx",
                    "description": "Lab exercise on Prompt Engineering related to sentiment analysis."
                },
                {
                    "title": "Lab_05_Prompt_Engineering_ChatBOT.docx",
                    "link": "./files/sample-content/vlabs/Lab_05_Prompt_Engineering_ChatBOT.docx",
                    "description": "Lab exercise on Prompt Engineering related to ChatBOT development."
                },
                {
                    "title": "Lab_06_ML_Image Classification of Cracked Floors.docx",
                    "link": "./files/sample-content/vlabs/Lab_06_ML_Image Classification of Cracked Floors.docx",
                    "description": "Lab exercise on Machine Learning (ML) focusing on image classification of cracked floors."
                },
                {
                    "title": "Lab_07_NLP_Sentence Splitter.docx",
                    "link": "./files/sample-content/vlabs/Lab_07_NLP_Sentence Splitter.docx",
                    "description": "Lab exercise on Natural Language Processing (NLP) regarding sentence splitting."
                },
                {
                    "title": "Lab_08_ML_Object Detection.docx",
                    "link": "./files/sample-content/vlabs/Lab_08_ML_Object Detection.docx",
                    "description": "Lab exercise on Machine Learning (ML) focusing on object detection."
                },
                {
                    "title": "Lab_08_NLP_Lemmatization.docx",
                    "link": "./files/sample-content/vlabs/Lab_08_NLP_Lemmatization.docx",
                    "description": "Lab exercise on Natural Language Processing (NLP) regarding lemmatization."
                },
                {
                    "title": "Lab_12_NLP_POS Tagger.docx",
                    "link": "./files/sample-content/vlabs/Lab_12_NLP_POS Tagger.docx",
                    "description": "Lab exercise on Natural Language Processing (NLP) focusing on POS tagging."
                },
                {
                    "title": "Lab_18_ML_Regression.docx",
                    "link": "./files/sample-content/vlabs/Lab_18_ML_Regression.docx",
                    "description": "Lab exercise on Machine Learning (ML) regarding regression."
                },
                {
                    "title": "Lab_18_ML_Vectors_Store.docx",
                    "link": "./files/sample-content/vlabs/Lab_18_ML_Vectors_Store.docx",
                    "description": "Lab exercise on Machine Learning (ML) related to vectors and storage."
                },
                {
                    "title": "Lab_21_ML_Object Detection.docx",
                    "link": "./files/sample-content/vlabs/Lab_21_ML_Object Detection.docx",
                    "description": "Lab exercise on Machine Learning (ML) focusing on object detection."
                },
                {
                    "title": "Lab_25_OpenAI_Fine Tuned Models.docx",
                    "link": "./files/sample-content/vlabs/Lab_25_OpenAI_Fine Tuned Models.docx",
                    "description": "Lab exercise on fine-tuning models using OpenAI."
                },
                {
                    "title": "Lab_26_OpenAI_Functions.docx",
                    "link": "./files/sample-content/vlabs/Lab_26_OpenAI_Functions.docx",
                    "description": "Lab exercise on using functions with OpenAI."
                },
                {
                    "title": "Lab_28_OpenAI_Chain of Thought Prompting.docx",
                    "link": "./files/sample-content/vlabs/Lab_28_OpenAI_Chain of Thought Prompting.docx",
                    "description": "Lab exercise on Chain of Thought Prompting with OpenAI."
                },
                {
                    "title": "Lab_30_LangChain_Prompt_Templates.docx",
                    "link": "./files/sample-content/vlabs/Lab_30_LangChain_Prompt_Templates.docx",
                    "description": "Lab exercise on prompt templates with LangChain."
                },
                {
                    "title": "Lab_31_LangChain_Maths With LangChain Tools.docx",
                    "link": "./files/sample-content/vlabs/Lab_31_LangChain_Maths With LangChain Tools.docx",
                    "description": "Lab exercise on using LangChain tools for mathematical purposes."
                },
                {
                    "title": "Lab_35_ML_NLP_HuggigFace NLP.docx",
                    "link": "./files/sample-content/vlabs/Lab_35_ML_NLP_HuggigFace NLP.docx",
                    "description": "Lab exercise on Natural Language Processing (NLP) using Hugging Face NLP."
                },
                {
                    "title": "Lab_38_Azure_OpenAI_Responsible_AI.docx",
                    "link": "./files/sample-content/vlabs/Lab_38_Azure_OpenAI_Responsible_AI.docx",
                    "description": "Lab exercise on responsible AI using Azure and OpenAI."
                }
            ]
        },
        {
            "title": "Case Studies",
            "list": [
                {
                    "title": "Casestudies_Prompt Engineering_(.NET_Java Track)_Ramesh_Oct 25th.xlsx",
                    "link": "./files/sample-content/case-studies/Casestudies_Prompt Engineering_(.NET_Java Track)_Ramesh_Oct 25th.xlsx",
                    "description": "Case study on Prompt Engineering for .NET and Java tracks by Ramesh, dated October 25th."
                },
                {
                    "title": "GenAI Python Case Study Project.pdf",
                    "link": "./files/sample-content/case-studies/GenAI Python Case Study Project.pdf",
                    "description": "Python case study project related to GenAI."
                }
            ]
        },
        {
            "title": "MCQs",
            "list": [
                {
                    "title": "MCQs for GenAI with Javascript.xlsx",
                    "link": "./files/sample-content/mcqs/MCQs for GenAI with Javascript.xlsx",
                    "description": "Multiple Choice Questions (MCQs) for GenAI focusing on Javascript."
                }
            ]
        },
        {
            "title": "TOC",
            "list": [
                {
                    "title": "AI Primer for Beginners-TOC.pdf",
                    "link": "./files/sample-content/toc/AI Primer for Beginners-TOC.pdf",
                    "description": "Table of Contents (TOC) for AI Primer for Beginners."
                },
                {
                    "title": "Flutter TOC.pdf",
                    "link": "./files/sample-content/toc/Flutter TOC.pdf",
                    "description": "Table of Contents (TOC) for Flutter."
                },
                {
                    "title": "NET TOC.pdf",
                    "link": "./files/sample-content/toc/NET TOC.pdf",
                    "description": "Table of Contents (TOC) for .NET."
                }
            ]
        },
        
        {
            "title": "Evaluation",
            "list": [
                {
                    "title": "Prompt Engineering Dotnet - Evaluation Sheet.xlsx",
                    "link": "./files/sample-content/evaluation/Prompt Engineering Dotnet - Evaluation Sheet.xlsx",
                    "description": "Evaluation sheet for Prompt Engineering related to .NET."
                },
                {
                    "title": "Prompt Engineering Dotnet TLA report(1355).xlsx",
                    "link": "./files/sample-content/evaluation/Prompt Engineering Dotnet TLA report(1355).xlsx",
                    "description": "TLA (Top Level Analysis) report for Prompt Engineering related to .NET with ID 1355."
                }
            ]
        },

    ]
};

function getContentSamplesJSON() {
    return contentSamplesJSON;
}

export default getContentSamplesJSON;