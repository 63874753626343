import React from 'react';

import BulletedTree from '../Common/Controls/BulletedTree';

import { getTechDesignsJSON } from "./data/techDesigns";

const FeaturedTechDesignDocuments = () => {

    const technicalDesignsJSON = getTechDesignsJSON();

    const listStyle = { border: '1px solid black', padding: '8px', borderRadius: '4px', backgroundColor: 'rgba(250, 250, 210, .5)', marginLeft: '10px', height: 'fit-content' };

    const subListStyle = { fontSize: '14px', paddingTop: '2px', paddingLeft: '2px' };

    const subListStyle2 = { paddingTop: '2px', paddingLeft: '2px' };

    return (
        <section id="skillsset2" className="services-area pt-20 gray-bg">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-20">
                        <div className="section-title text-center">
                            <h5 className="sub-title mb-15">Featured Tech Design Documents</h5>
                            <h5 className="title">Clean Architecture</h5>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <BulletedTree title="" subListStyle={subListStyle2} showParentBullet={false} data={technicalDesignsJSON.data} />
            </div>
        </section>
    );
};

export default FeaturedTechDesignDocuments;