const ServiceOfferings = {
    "data": [
        {
            "title": "AI/ML/Gen AI - Early Adoption",
            "description": "Integrating artificial intelligence and machine learning into existing systems, from data preparation to model deployment. Covers custom ML solutions using frameworks like TensorFlow, PyTorch, and OpenAI API.",
            "children": [
                {
                    "title": "Gen AI Development",
                    "chipItems": ["Agents", "Text Actions", "Assistents", "Conersational ChatBOTs", "Open AI ChatGPT", "Prompt Engineering", "Embeddings", "LangChain", "RAG", "LLM", "Generative AI"],
                    "list": [
                        { "text": "Designing and implementing Agent-based systems using Context Windows, Text Actions, and Conversational ChatBOTs.", "display": false },
                        { "text": "Designing and implementing conversational AI solutions using ChatGPT to automate customer support, lead generation, and other tasks.", "display": false },
                        { "text": "Fine-tuned models: Tailoring ChatGPT and other LLM models to specific domains or use cases by fine-tuning them on relevant datasets.", "display": false },
                        { "text": "Create embeddings, storing in vector databases, and querying them to find similar items or clusters of items or to prompt.", "display": false },
                        { "text": "Provide Gen AI services involving prompt engineering, prompting techniques, design with best practices including bias mitigation and ethical considerations.", "display": false },
                        { "text": "Implement advanced concepts like functions, RAG, LangChain, etc. to create advanced Gen AI solutions.", "display": false }
                    ]
                },
                   
                {
                    "title": "NLP",
                    "chipItems": ["NLP Models", "Text Preprocessing", "Named Entity Recognition", "Machine Translation", "Text Classification"],
                    "list": [
                        { "text": "Designing and training bespoke NLP models for specific use cases or domains.", "display": false },
                        { "text": "Providing expert guidance and advice on NLP implementation strategies and best practices.", "display": false },
                        { "text": "Text Data Preprocessing: Cleaning, tokenizing, and normalizing text data to prepare it for NLP tasks.", "display": false },
                        { "text": "NLP Model Evaluation and Benchmarking: Assessing NLP model performance using standard benchmarks and metrics.", "display": false },
                        { "text": "Text Classification and Categorization: Building models to automatically categorize text data into predefined categories or labels.", "display": false },
                        { "text": "Named Entity Recognition (NER): Creating models to identify and extract named entities such as people, organizations, and locations from text data.", "display": false },
                        { "text": "Implementing machine translation systems to translate text between different languages, enabling multilingual support.", "display": false },
                        { "text": "Creating systems to generate coherent text passages or summaries from input data, facilitating content creation and synthesis.", "display": false },
                        { "text": "Integrating NLP models into existing applications and deploying them to production environments for seamless integration and optimal performance.", "display": false }
                    ]
                },
                {
                    "title": "Data Science/AI/ML",
                    "chipItems": ["AI Architectures", "Data Pipelines", "Model Training", "Cloud-native", "Distributed Computing"],
                    "list": [
                        { "text": "Design AI/ML architectures, including data pipelines, model training, and inference systems, to support machine learning applications.", "display": false },
                        { "text": "Architect scalable and resilient AI/ML systems, leveraging cloud-native technologies and distributed computing to optimize performance and resource utilization.", "display": false },
                        { "text": "Define data management strategies, including data collection, storage, and preprocessing, to ensure data quality and availability for model training and inference.", "display": false },
                        { "text": "Design AI/ML model architectures, selecting appropriate algorithms and frameworks to meet business objectives and user needs.", "display": false },
                        { "text": "Develop AI/ML architectural blueprints and roadmaps, guiding the implementation of technical solutions and aligning with business objectives.", "display": false }
                    ]
                },

                {
                    "title": "Develop Use Cases",
                    "chipItems": ["Supervised Learning", "Unsupervised Learning", "Classification Models", "Regression Models", "Recommendation Systems"],
                    "list": [
                        { "text": "Develop supervised learning models for classification tasks such as sentiment analysis and spam detection.", "display": false },
                        { "text": "Implement unsupervised learning algorithms for clustering data and identifying patterns in unlabelled datasets.", "display": false },
                        { "text": "Design regression models for predicting continuous variables, such as house prices and stock prices, based on historical data.", "display": false },
                        { "text": "Create classification models for identifying categories or labels for input data, such as email classification and document categorization.", "display": false },
                        { "text": "Implement clustering algorithms for grouping similar data points together, such as customer segmentation and market basket analysis.", "display": false },
                        { "text": "Develop object detection models using deep learning techniques for identifying and localizing objects within images or videos.", "display": false },
                        { "text": "Design natural language processing (NLP) pipelines for tasks such as text summarization, named entity recognition (NER), and part-of-speech tagging.", "display": false },
                        { "text": "Build sentiment analysis models to analyze and classify opinions expressed in text data, such as social media posts and customer reviews.", "display": false },
                        { "text": "Create recommendation systems using collaborative filtering and content-based filtering techniques to personalize content and product recommendations.", "display": false },
                        { "text": "Design AI-powered decision support systems to assist users in making informed choices based on data analysis.", "display": false }
                    ]
                },
                {
                    "title": "Gen AI Development",
                    "chipItems": ["ChatGPT", "NLP Applications", "Text Summarization", "Language Translation", "Content Generation"],
                    "list": [
                        { "text": "Designing and implementing conversational AI solutions using ChatGPT to automate customer support, lead generation, and other tasks.", "display": false },
                        { "text": "Fine-tuned models: Tailoring ChatGPT and other LLM models to specific domains or use cases by fine-tuning them on relevant datasets.", "display": false },
                        { "text": "Integrating ChatGPT and other models into existing applications, websites, or platforms to enhance user interaction and engagement.", "display": false },
                        { "text": "Developing NLP applications and tools using ChatGPT for tasks such as sentiment analysis, language translation, and text summarization.", "display": false },
                        { "text": "Creating AI-powered content generation tools using ChatGPT to automate writing, coding, and other creative tasks.", "display": false },
                        { "text": "Building AI-powered recommendation systems using ChatGPT to personalize content, product recommendations, and user experiences.", "display": false },
                        { "text": "Developing NLP applications and tools using ChatGPT for tasks such as sentiment analysis, language translation, and text summarization.", "display": false },
                        { "text": "Designing and implementing conversational AI solutions using ChatGPT to automate customer support, lead generation, and other tasks.", "display": false }
                    ]
                },
                {
                    "title": "NLP",
                    "chipItems": ["Custom NLP Models", "Sentiment Analysis", "NER", "Text Categorization", "Multilingual Support"],
                    "list": [
                        { "text": "Custom NLP Model Development: Designing and training bespoke NLP models for specific use cases or domains.", "display": false },
                        { "text": "NLP Consulting and Strategy: Providing expert guidance and advice on NLP implementation strategies and best practices.", "display": false },
                        { "text": "Text Data Preprocessing: Cleaning, tokenizing, and normalizing text data to prepare it for NLP tasks.", "display": false },
                        { "text": "NLP Model Evaluation and Benchmarking: Assessing NLP model performance using standard benchmarks and metrics.", "display": false },
                        { "text": "Sentiment Analysis Solutions: Developing systems to analyze and classify text sentiment, enabling sentiment-driven insights.", "display": false },
                        { "text": "Text Classification and Categorization: Building models to automatically categorize text data into predefined categories or labels.", "display": false },
                        { "text": "Named Entity Recognition (NER): Creating models to identify and extract named entities such as people, organizations, and locations from text data.", "display": false },
                        { "text": "Language Translation Services: Implementing machine translation systems to translate text between different languages, enabling multilingual support.", "display": false },
                        { "text": "Text Generation and Summarization: Creating systems to generate coherent text passages or summaries from input data, facilitating content creation and synthesis.", "display": false },
                        { "text": "NLP Integration and Deployment: Integrating NLP models into existing applications and deploying them to production environments for seamless integration and optimal performance.", "display": false }
                    ]
                }
            ]
        },
        {
            "title": "Expert Consulting",
            "description": "Offering advanced consulting services for niche areas like Gen AI, complex automation, database migrations, process discovery, and more. Tailored solutions to tackle high-level technical challenges and business goals.",
            "children": [
                {
                    "title": "Performance",
                    "chipItems": ["Performance Bottlenecks", "Load Testing", "CDN", "Monitoring Tools", "Caching"],
                    "list": [
                        { "text": "Analyzing existing systems to identify performance bottlenecks and areas for improvement.", "display": false },
                        { "text": "Designing systems with scalability in mind to handle increasing loads and user demands.", "display": false },
                        { "text": "Conducting thorough reviews of system architecture to identify potential performance issues and propose optimizations.", "display": false },
                        { "text": "Performing load testing to evaluate system performance under various load conditions and identify performance limits.", "display": false },
                        { "text": "Designing and implementing effective caching strategies to reduce latency and improve response times.", "display": false },
                        { "text": "Optimizing database queries, indexes, and schema design to improve data retrieval and processing speed.", "display": false },
                        { "text": "Implementing CDNs to cache and deliver content closer to end-users, reducing latency and improving performance.", "display": false },
                        { "text": "Designing systems using microservices architecture to enable horizontal scaling and improve fault isolation.", "display": false },
                        { "text": "Setting up monitoring tools to track system performance metrics and identify performance degradation in real-time.", "display": false },
                        { "text": "Establishing processes for continuous performance optimization, including regular performance reviews and optimizations.", "display": false },
                        { "text": "Optimizing infrastructure configurations, such as server configurations and network settings, to improve system performance.", "display": false },
                        { "text": "Optimizing continuous integration and continuous deployment pipelines to automate performance testing and ensure performance considerations are addressed early in the development lifecycle.", "display": false }
                    ]
                },
                {
                    "title": "NFRs",
                    "chipItems": ["Maintainability", "Scalability", "Resilience", "Fault Tolerance", "Availability"],
                    "list": [
                        { "text": "Maintainability: Develop codebase with clear structure for easy maintenance.", "display": false },
                        { "text": "Portability: Ensure compatibility across diverse platforms and environments.", "display": false },
                        { "text": "Interoperability: Enable seamless integration with external systems and APIs.", "display": false },
                        { "text": "Accessibility: Provide support for users with disabilities and diverse needs.", "display": false },
                        { "text": "Localization: Support multiple languages and cultural preferences.", "display": false },
                        { "text": "Scalability: Design systems to handle increasing loads seamlessly.", "display": false },
                        { "text": "Performance: Optimize response times for enhanced user experience.", "display": false },
                        { "text": "Reliability: Implement fault-tolerant mechanisms to minimize downtime.", "display": false },
                        { "text": "Availability: Ensure high system availability through redundancy measures.", "display": false },
                        { "text": "Usability: Design intuitive interfaces for ease of use and accessibility.", "display": false },
                        { "text": "Fault Tolerance: Implement measures to mitigate the impact of system failures.", "display": false },
                        { "text": "Resilience: Design systems to withstand and recover from failures gracefully.", "display": false }
                    ]
                },
                {
                    "title": "Establishing Best Practices",
                    "chipItems": ["Architecture Governance", "Cross-functional Collaboration", "Emerging Technologies"],
                    "list": [
                        { "text": "Lead architecture governance processes, defining standards, guidelines, and best practices, and ensuring adherence through code reviews and architectural assessments.", "display": false },
                        { "text": "Collaborate with cross-functional teams, including product managers, business analysts, and operations teams, to align technical solutions with business objectives and requirements.", "display": false },
                        { "text": "Stay abreast of emerging technologies, industry trends, and best practices, continuously learning and adapting to drive innovation and maintain technical excellence.", "display": false }
                    ]
                },
                {
                    "title": "Advanced DevOps, Automation & Network Setup",
                    "chipItems": ["Kubernetes", "CI/CD Pipelines", "Infrastructure as Code", "VPN", "Cloud-native"],
                    "list": [
                        { "text": "Implement advanced automation and CI/CD pipelines for optimized deployment workflows.", "display": false },
                        { "text": "Set up infrastructure-as-code (IaC) solutions using tools like Terraform and Ansible.", "display": false },
                        { "text": "Design and configure complex network setups, including VPNs, cloud-native security, and multi-region configurations.", "display": false },
                        { "text": "Optimize Kubernetes-based infrastructure for scalability and performance in cloud-native environments.", "display": false }
                    ]
                },
                {
                    "title": "Roadmaps for Developing Complex Projects (Supply Chain, Investment Brokerage)",
                    "chipItems": ["Supply Chain Automation", "Investment Brokerage Systems", "ERP Integration", "Blockchain"],
                    "list": [
                        { "text": "Consult on supply chain automation projects using advanced technology like IoT, AI, and blockchain.", "display": false },
                        { "text": "Develop complex investment brokerage systems that integrate with global financial markets.", "display": false },
                        { "text": "Design and implement ERP integration strategies for large-scale business processes.", "display": false },
                        { "text": "Leverage blockchain for enhanced security and transparency in supply chain and brokerage systems.", "display": false }
                    ]
                },
                {
                    "title": "Cloud Setup & Management",
                    "list": [
                        { "text": "Design and implement secure virtual private networks (VPNs) for hybrid cloud and on-premise connectivity.", "display": false },
                        { "text": "Set up and configure multi-region network architectures for improved availability and performance.", "display": false },
                        { "text": "Optimize network security for cloud-native, on-premise, and hybrid environments, ensuring compliance with industry standards.", "display": false },
                        { "text": "Leverage cloud-based services like AWS Direct Connect and Azure ExpressRoute to enhance network connectivity.", "display": false },
                        { "text": "Implement advanced firewall and intrusion detection systems (IDS) to secure network traffic across environments.", "display": false },
                        { "text": "Manage and monitor cloud resources and virtual networks using tools like AWS VPC, Google Cloud VPC, and Azure Virtual Networks.", "display": false },
                        { "text": "Design resilient network architectures that automatically failover in case of outages or disruptions.", "display": false },
                        { "text": "Ensure seamless integration between on-premise data centers and cloud providers using hybrid networking solutions.", "display": false }
                    ]
                },
                {
                    "title": "Database Migration",
                    "chipItems": ["Cloud Databases", "SQL to NoSQL", "Data Migration", "Amazon RDS", "Google Cloud SQL"],
                    "list": [
                        { "text": "Migrate on-premise databases to cloud platforms such as AWS, Azure, or Google Cloud.", "display": false },
                        { "text": "Perform migrations from SQL databases to NoSQL solutions to handle high scalability and performance.", "display": false },
                        { "text": "Implement data migration strategies with minimal downtime and data integrity preservation.", "display": false },
                        { "text": "Optimize databases post-migration to ensure seamless operation and scalability.", "display": false }
                    ]
                },
                {
                    "title": "Legacy Platform Migration",
                    "chipItems": ["Legacy Systems", "Platform Modernization", "Cloud-native Migration", "Mainframe to Cloud"],
                    "list": [
                        { "text": "Migrate legacy platforms (e.g., mainframes) to modern, cloud-native environments.", "display": false },
                        { "text": "Modernize legacy applications to improve performance, scalability, and maintainability.", "display": false },
                        { "text": "Re-engineer legacy workflows to integrate with modern cloud infrastructure and services.", "display": false },
                        { "text": "Minimize downtime and risk during migration from legacy systems to cloud or hybrid platforms.", "display": false }
                    ]
                },
                {
                    "title": "Data Science & Analytics Strategy",
                    "chipItems": ["Data Strategy", "Big Data Analytics", "Machine Learning Models", "Business Intelligence"],
                    "list": [
                        { "text": "Develop data governance strategies to manage and secure data assets.", "display": false },
                        { "text": "Build advanced analytics models to derive actionable insights from large datasets.", "display": false },
                        { "text": "Leverage machine learning to optimize business processes and decision-making.", "display": false },
                        { "text": "Implement business intelligence solutions to improve decision-making across the organization.", "display": false }
                    ]
                },
                {
                    "title": "Process Discovery & Automation",
                    "chipItems": ["Process Mapping", "RPA", "Workflow Automation", "Business Process Reengineering"],
                    "list": [
                        { "text": "Map out existing business processes to identify inefficiencies and bottlenecks.", "display": false },
                        { "text": "Implement Robotic Process Automation (RPA) to automate repetitive tasks and improve efficiency.", "display": false },
                        { "text": "Re-engineer business processes to streamline operations and improve workflows.", "display": false },
                        { "text": "Automate critical workflows using AI, RPA, and cloud-based platforms.", "display": false }
                    ]
                },
                {
                    "title": "KRA & KPI Goal Setting",
                    "chipItems": ["Goal Setting", "KPI Dashboards", "Performance Metrics", "Strategic Planning"],
                    "list": [
                        { "text": "Consult on defining Key Result Areas (KRA) and Key Performance Indicators (KPI) for business objectives.", "display": false },
                        { "text": "Develop KPI dashboards to track performance metrics across teams and departments.", "display": false },
                        { "text": "Assist in strategic planning to align goals with measurable performance outcomes.", "display": false },
                        { "text": "Optimize performance management systems to continuously monitor and adjust KRA/KPI goals.", "display": false }
                    ]
                },
                {
                    "title": "ISO, KPMG, InfoSec Auditing",
                    "chipItems": ["ISO Compliance", "KPMG Audits", "Information Security", "Risk Assessments"],
                    "list": [
                        { "text": "Conduct ISO compliance audits and provide recommendations for achieving certification.", "display": false },
                        { "text": "Facilitate KPMG audits to ensure compliance with industry regulations and best practices.", "display": false },
                        { "text": "Perform comprehensive information security assessments to identify risks and vulnerabilities.", "display": false },
                        { "text": "Guide organizations through risk assessments and help them achieve InfoSec compliance.", "display": false }
                    ]
                }
            ]
        },
        {
            "title": "Website/Mobile App Development",
            "description": "Building responsive web and mobile applications using modern frameworks such as React, Angular, Vue, React Native, Ionic, MAUI etc.",
            "chipItems": ["React", "Angular", "Vue", "Responsive Design", "React Native", "Ionic", "MAUI"],
            "children": [
                {
                    "title": "Application Architecture",
                    "list": [
                        { "text": "Craft intuitive and visually appealing user interfaces to enhance user engagement.", "display": true },
                        { "text": "Design seamless user flows for optimal navigation and interaction.", "display": false },
                        { "text": "Create wireframes and prototypes to validate design concepts and gather feedback.", "display": false },
                        { "text": "Optimize UI/UX design for different screen sizes and device resolutions.", "display": true },
                        { "text": "Design and implement responsive web applications using modern front-end frameworks like React, Angular, and Vue.js.", "display": true },
                        { "text": "Design Stores and State Management solutions like Redux, NgRX, RxJS to manage application state and data flow.", "display": false },
                        { "text": "Design accessibility features to ensure inclusivity and compliance.", "display": false }
                    ]
                },
                {
                    "title": "User Experience & Interface Design",
                    "list": [
                        { "text": "Craft intuitive and visually appealing user interfaces to enhance user engagement.", "display": false },
                        { "text": "Design seamless user flows for optimal navigation and interaction.", "display": false },
                        { "text": "Create wireframes and prototypes to validate design concepts and gather feedback.", "display": false },
                        { "text": "Optimize UI/UX design for different screen sizes and device resolutions.", "display": false },
                        { "text": "Implement accessibility features to ensure inclusivity and compliance.", "display": false },
                        { "text": "Designing responsive layouts for various devices and screen sizes.", "display": false },
                        { "text": "Conducting user research to understand user needs and preferences.", "display": false },
                        { "text": "Creating user personas to guide design decisions.", "display": true },
                        { "text": "Conducting accessibility audits to ensure inclusivity.", "display": false },
                        { "text": "Conducting usability testing to evaluate user satisfaction.", "display": false }
                    ]
                },
                {
                    "title": "UI Development",
                    "list": [
                        { "text": "Implement responsive and user-friendly front-end interfaces using HTML, CSS, and JavaScript.", "display": false },
                        { "text": "Design and implement responsive web applications using modern front-end frameworks like React, Angular, and Vue.js.", "display": false },
                        { "text": "Build beautiful and interactive user interfaces using custom HTML, CSS, libraries like Bootstrap, Material UI, Vuetify, Fluent etc.", "display": true },
                        { "text": "Build and deploy RESTful APIs to facilitate communication between systems.", "display": true },
                        { "text": "Integrate third-party APIs and services to enhance application functionality.", "display": false }
                    ]
                }
            ]
        },
        {
            "title": "Full Stack Development",
            "description": "From concept to deployment across front-end, back-end, database layers & other components with technologies such as Node.js, .NET, Java, Python, PHP.",
            "chipItems": ["Node.js", ".NET", "Java", "Python", "PHP", "Full Stack", "Scalable Solutions"],
            "children": [
                {
                    "title": "Programming - Full Stack Development Services",
                    "list": [
                        { "text": "Develop projects in multiple tech stacks - .NET, Java, Python, Node.js, React, Angular, Vue.js, Express, etc.", "display": false },
                        { "text": "Analyze requirements, design and develop scalable software solutions.", "display": false },
                        { "text": "Write clean, maintainable code following best practices and coding standards.", "display": false },
                        { "text": "Designing modular and reusable architecture for flexibility and maintainability.", "display": false },
                        { "text": "Evaluate and implement design patterns for efficient and scalable code.", "display": false },
                        { "text": "Implement appropriate architectural patterns for optimal performance.", "display": false },
                        { "text": "Implement appropriate data structures and algorithms for optimal performance.", "display": false },
                        { "text": "Develop and maintain database schemas, queries, and stored procedures.", "display": false },
                        { "text": "Implement responsive and user-friendly front-end interfaces using HTML, CSS, and JavaScript.", "display": false },
                        { "text": "Optimize application performance and scalability through efficient coding techniques.", "display": false },
                        { "text": "Integrate third-party APIs and services to enhance application functionality.", "display": false },
                        { "text": "Evaluate third-party libraries and frameworks for suitability and compatibility.", "display": false },
                        { "text": "Conduct code refactoring to improve code quality.", "display": false },
                        { "text": "Build and deploy RESTful APIs to facilitate communication between systems.", "display": false },
                        { "text": "Implement error handling mechanisms for robustness.", "display": false },
                        { "text": "Implement logging and monitoring systems for real-time insights.", "display": false },
                        { "text": "Provide technical support and troubleshooting for software applications.", "display": false }
                    ]
                },
                {
                    "title": "API Development",
                    "list": [
                        { "text": "Build and deploy RESTful APIs to facilitate communication between systems.", "display": false },
                        { "text": "Utilize API management platforms to monitor, secure, and document APIs.", "display": false },
                        { "text": "Implement GraphQL APIs for efficient data retrieval and manipulation.", "display": false }
                    ]
                },
                {
                    "title": "Data Management",
                    "list": [
                        { "text": "Design database schemas, queries, and stored procedures.", "display": false },
                        { "text": "Evaluate and implement appropriate database technologies like SQL, NoSQL, Data Warehouse, Data Lake, Graph, Timeseries, Vector etc.", "display": false },
                        { "text": "Optimize database queries to enhance efficiency.", "display": false },
                        { "text": "Utilize cloud-based storage solutions like Amazon S3 or Google Cloud Storage for data storage.", "display": false },
                        { "text": "Implement cloud-based databases like Amazon RDS or Google Cloud SQL for data management.", "display": false }
                    ]
                },
                {
                    "title": "Cloud Computing",
                    "list": [
                        { "text": "Utilize cloud services like AWS Lambda or Google Cloud Functions for serverless computing.", "display": false },
                        { "text": "Deploy applications using containerization technologies like Docker and Kubernetes.", "display": false },
                        { "text": "Implement cloud-native architectures leveraging microservices and serverless paradigms.", "display": false }
                    ]
                },
                {
                    "title": "Cross-Platform Development",
                    "list": [
                        { "text": "Develop mobile apps that run seamlessly on multiple platforms like iOS and Android.", "display": false },
                        { "text": "Utilize frameworks like React Native or Flutter to build cross-platform applications.", "display": false },
                        { "text": "Write platform-agnostic code to reduce development time and effort.", "display": false },
                        { "text": "Ensure consistent user experience across different operating systems.", "display": false },
                        { "text": "Leverage native modules for platform-specific functionalities when needed.", "display": false }
                    ]
                },
                {
                    "title": "Performance Optimization",
                    "list": [
                        { "text": "Implement caching mechanisms for improved performance.", "display": false },
                        { "text": "Optimize database queries to enhance efficiency.", "display": false },
                        { "text": "Conduct performance profiling to identify optimization opportunities.", "display": false },
                        { "text": "Optimize hardware configurations to minimize latency.", "display": false },
                        { "text": "Optimize app performance to minimize loading times and maximize responsiveness.", "display": false },
                        { "text": "Use concepts of treeshaking, side effects, lazy loading, chunking, minification to optimize UI performance.", "display": false },
                        { "text": "Implement content delivery networks for faster content delivery.", "display": false }
                    ]
                },
                {
                    "title": "Security",
                    "list": [
                        { "text": "Implement authentication and authorization mechanisms to secure applications.", "display": false },
                        { "text": "Implement encryption techniques to protect sensitive data.", "display": false },
                        { "text": "Conduct security audits to identify vulnerabilities and risks.", "display": false },
                        { "text": "Conduct penetration testing to identify security weaknesses.", "display": false }
                    ]
                },
                {
                    "title": "Data Protection",
                    "list": [
                        { "text": "Implement encryption techniques to protect sensitive data.", "display": false },
                        { "text": "Conduct compliance assessments to ensure regulatory adherence.", "display": false },
                        { "text": "Conduct data integrity checks to ensure data accuracy.", "display": false },
                        { "text": "Implement backup and restore procedures for data protection.", "display": false }
                    ]
                },
                {
                    "title": "NFRs",
                    "list": [
                        { "text": "Scalability: Design architecture for increased workload, utilizing cloud-based infrastructure for elastic scalability.", "display": false },
                        { "text": "Reliability: Implement fault-tolerant systems with redundant components and failover mechanisms.", "display": false },
                        { "text": "Availability: Design systems with high availability, utilizing load balancing and geo-replication.", "display": false },
                        { "text": "Performance: Optimize system performance with caching mechanisms and performance testing.", "display": false },
                        { "text": "Security: Implement robust security measures to protect against unauthorized access.", "display": false },
                        { "text": "Maintainability: Design codebase for easy maintenance and updates, utilizing clean coding practices.", "display": false },
                        { "text": "Usability: Ensure user-friendly interfaces and intuitive design for enhanced user experience.", "display": false },
                        { "text": "Interoperability: Design systems to seamlessly integrate with third-party services and APIs.", "display": false },
                        { "text": "Compliance: Ensure compliance with industry regulations and standards, implementing necessary security and privacy measures.", "display": false },
                        { "text": "Resilience: Build systems resilient to failures, with proactive monitoring and recovery strategies.", "display": false },
                        { "text": "Configurability: Design systems with configurable parameters for easy customization and adaptability.", "display": false }
                    ]
                }
            ]
        },
        {
            "title": "Architecture & Modernization",
            "description": "Designing scalable, resilient, and secure architectures for web, mobile, and cloud-based systems. Conducting architectural reviews, defining best practices, and guiding implementation to align with business objectives.",
            "chipItems": ["Microservices", "Cloud-native", "Scalable Architecture", "Resilient Systems", "Best Practices"],
            "children": [
                {
                    "title": "General Architecture",
                    "chipItems": ["Scalability", "Reliability", "Cloud-native", "Distributed Systems"],
                    "list": [
                        { "text": "Design scalable, resilient architectures using microservices, distributed systems, and cloud-native technologies for optimal performance and reliability.", "display": false },
                        { "text": "Conduct thorough architectural reviews, identifying potential bottlenecks and proposing solutions to optimize system efficiency and resource utilization.", "display": false },
                        { "text": "Define architectural patterns and best practices, ensuring consistency and alignment across development teams and projects.", "display": false },
                        { "text": "Design end-to-end solutions, including front-end, back-end, and data layers, to meet business requirements and user needs.", "display": false }
                    ]
                },
                {
                    "title": "Technical Architecture",
                    "chipItems": ["Security", "Data Management", "API Architecture", "Migration Strategies"],
                    "list": [
                        { "text": "Design scalable, resilient, and secure application architectures for web, mobile, and cloud-based systems.", "display": false },
                        { "text": "Architect data management solutions, including database design, data modeling, and data migration strategies, ensuring data integrity, availability, and scalability.", "display": false },
                        { "text": "Design robust and scalable API architectures, ensuring interoperability and seamless integration across diverse systems and platforms.", "display": false },
                        { "text": "Devise migration strategies, selecting appropriate cloud services and orchestrating migration processes to ensure minimal downtime and maximum scalability.", "display": false },
                        { "text": "Define robust security strategies, including encryption, access control, and authentication mechanisms, to safeguard data and protect against cyber threats.", "display": false },
                        { "text": "Develop architectural blueprints and roadmaps, guiding the implementation of technical solutions and aligning with business objectives.", "display": false }
                    ]
                },
                {
                    "title": "Cloud Native Architecture",
                    "chipItems": ["Serverless", "Docker", "Kubernetes", "Microservices", "Cloud-native"],
                    "list": [
                        { "text": "Utilize cloud services like AWS Lambda or Google Cloud Functions for serverless computing.", "display": false },
                        { "text": "Deploy applications using containerization technologies like Docker and Kubernetes.", "display": false },
                        { "text": "Implement cloud-native architectures leveraging microservices and serverless paradigms.", "display": false }
                    ]
                }
            ]
        },
        {
            "title": "Solution Architecting",
            "description": "Technical solutions designed to meet specific business requirements. Involves defining solution blueprints, integrating data flows, and selecting technology components to meet those needs. The goal is to ensure all architectural decisions are well-documented and in line with best practices.",
            "chipItems": ["Solution Blueprints", "Data Integration", "Technology Components", "Best Practices"],
            "children": [
                {
                    "title": "Solution Architecture",
                    "chipItems": ["Client Needs", "Interoperability", "Strategic Decision-making", "Stakeholder Communication"],
                    "list": [
                        { "text": "Crafting comprehensive architectural designs tailored to client needs.", "display": false },
                        { "text": "Seamlessly integrating disparate systems to ensure interoperability and efficiency.", "display": false },
                        { "text": "Providing expert advice and guidance on technical matters to support strategic decision-making.", "display": false },
                        { "text": "Analyzing client requirements to identify key objectives and design effective solutions.", "display": false },
                        { "text": "Optimizing system performance through efficient design and implementation strategies.", "display": false },
                        { "text": "Facilitating communication between stakeholders to ensure alignment and transparency throughout the project lifecycle.", "display": false }
                    ]
                },
                {
                    "title": "Technology Selection & Integration",
                    "chipItems": ["Technology Evaluation", "Data Flow Integration", "System Components"],
                    "list": [
                        { "text": "Evaluating and selecting appropriate technology components to meet business objectives.", "display": false },
                        { "text": "Ensuring seamless integration of data flows between different systems and applications.", "display": false },
                        { "text": "Identifying potential risks and ensuring technology components align with future scalability requirements.", "display": false },
                        { "text": "Ensuring proper documentation of all architectural decisions and technology choices.", "display": false },
                        { "text": "Collaborating with development teams to ensure smooth implementation of selected technologies.", "display": false }
                    ]
                }
            ]
        },
        {
            "title": "DevOps & CI/CD Automation",
            "description": "Setting up automated build, test, and deployment pipelines using tools like Jenkins, GitLab CI, and Azure DevOps. Infrastructure as code (IaC) using Terraform, and container orchestration with Kubernetes, to ensure consistent deployments and efficient release cycles.",
            "children": [
                {
                    "title": "Quality Assurance Services",
                    "chipItems": ["Unit Testing", "Load Testing", "Stress Testing", "Automated Testing", "Usability Testing"],
                    "list": [
                        { "text": "Conduct unit testing and debugging to ensure software reliability and stability.", "display": false },
                        { "text": "Perform load testing to ensure performance and scalability of applications.", "display": false },
                        { "text": "Conducting stress testing to evaluate system stability.", "display": false },
                        { "text": "Utilize automated testing tools and frameworks to streamline testing processes and improve efficiency.", "display": false },
                        { "text": "Conducting usability testing to enhance user satisfaction.", "display": false }
                    ]
                },
                {
                    "title": "Continuous Integration and Deployment Services",
                    "chipItems": ["CI/CD Pipelines", "Version Control", "Continuous Deployment", "Real-time Monitoring", "Automated Testing"],
                    "list": [
                        { "text": "Set up automated build and deployment pipelines for efficient development workflows.", "display": false },
                        { "text": "Integrate version control systems like Git for collaborative development.", "display": false },
                        { "text": "Automate testing processes to ensure code quality and reliability.", "display": false },
                        { "text": "Implement continuous deployment strategies for seamless app updates and releases.", "display": false },
                        { "text": "Utilize monitoring tools to track app performance and user feedback in real-time.", "display": false }
                    ]
                },
                {
                    "title": "Environment Setup & Maintenance Support",
                    "chipItems": ["Development Environments", "Staging", "Production", "Infrastructure as Code", "Monitoring", "Disaster Recovery"],
                    "list": [
                        { "text": "Design and set up development environments for rapid code iteration and testing.", "display": false },
                        { "text": "Configure testing environments to simulate production conditions and ensure accuracy in testing results.", "display": false },
                        { "text": "Set up staging environments to enable final pre-production testing with realistic data and configurations.", "display": false },
                        { "text": "Deploy and optimize production environments for scalability, security, and availability.", "display": false },
                        { "text": "Provide ongoing maintenance and support to ensure stability and smooth operations across all environments.", "display": false },
                        { "text": "Automate environment provisioning using Infrastructure as Code (IaC) tools like Terraform.", "display": false },
                        { "text": "Implement monitoring and alerting systems to detect and address issues in real-time across all environments.", "display": false },
                        { "text": "Ensure rollback and disaster recovery plans are in place for all environments to minimize downtime.", "display": false }
                    ]
                }
            ]
        },
        {
            "title": "Security & Compliance Guidance",
            "description": "Integrating security throughout the software lifecycle, using practices like threat modeling, code analysis, and vulnerability management. Covers encryption, access management, and compliance with standards like GDPR and ISO 27001 to build secure, compliant systems.",
            "children": [
                {
                    "title": "Security",
                    "chipItems": ["Encryption", "Access Control", "Data Integrity", "Audit Logging", "Privacy", "Vulnerability Management"],
                    "list": [
                        { "text": "Ensure robust encryption methods for data protection.", "display": false },
                        { "text": "Adhere to industry regulations and standards for legal compliance.", "display": false },
                        { "text": "Implement measures to prevent unauthorized data modification and maintain data integrity.", "display": false },
                        { "text": "Define and enforce user access policies and permissions.", "display": false },
                        { "text": "Enable comprehensive logging and auditing for accountability.", "display": false },
                        { "text": "Safeguard user data and ensure compliance with privacy regulations.", "display": false }
                    ]
                },
                {
                    "title": "Industry Compliance Protocols",
                    "chipItems": ["GDPR", "HIPAA", "PCI-DSS", "ISO 27001", "SOC 2", "Domain-specific Regulations"],
                    "list": [
                        { "text": "Ensure compliance with GDPR to protect personal data and privacy in the European Union.", "display": false },
                        { "text": "Implement HIPAA-compliant practices for safeguarding health information in the healthcare industry.", "display": false },
                        { "text": "Adhere to PCI-DSS requirements to secure payment card transactions and protect cardholder data.", "display": false },
                        { "text": "Achieve ISO 27001 certification to establish an information security management system (ISMS).", "display": false },
                        { "text": "Comply with SOC 2 standards to ensure data security and integrity in service organizations.", "display": false },
                        { "text": "Implement domain-specific compliance measures for industries such as finance, healthcare, and government.", "display": false }
                    ]
                }
            ]
        }
    ]
};

function getServiceOfferings() {
    return ServiceOfferings;
}

export default getServiceOfferings;