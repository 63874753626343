import React from 'react';

import VideoCard from './VideoCard';
import PremiumCard from '../../Common/Controls/PremiumCard';

import './PageTop.css';
import LinkButton from '../../Common/Controls/LinkButton';

function PageTop() {
    return (
        <section id="home-page-top" className="rameshv-container-row">
            <div className="rameshv-sub-container-col col-lg-12 col-sm-12 mt-175">
                <div className="header-banner d-flex align-items-center" style={{ 'height': 'fit-content' }}>
                    <div className="banner-content">
                        <h4 className="banner-title wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s">Welcome!</h4>
                        <h1 className="title mt-10 wow fadeInUp" style={{ 'fontSize': '32px', 'color': 'grey' }}><span>You're</span> in the digital realm of Ramesh</h1>
                        <h4 className="sub-title mb-40" style={{ 'fontSize': '24px', 'color': 'grey' }}>where innovation meets expertise in the Software Universe.</h4>
                        <h5 className="banner-contact1 wow fadeInUp" data-wow-duration="1.2s" data-wow-delay="1.2s" rel="nofollow" >
                        <svg width="42px" height="32px" version="1.1" id="Uploaded to svgrepo.com" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 42 32"  fill="#82ec3c"><g id="SVGRepo_bgCarrier"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path class="stone_een" d="M7,25c0,0.552-0.448,1-1,1H1c-0.552,0-1-0.448-1-1V10c0-0.552,0.448-1,1-1h5c0.552,0,1,0.448,1,1V25z M20.5,23c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5H23v-3h-2.5c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5H23v-3h-2.5 c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5H30c1.105,0,2-0.895,2-2v0c0-1.105-0.895-2-2-2h-9l-1.535-1.535 C18.527,7.527,17.255,7,15.929,7h-2.858c-1.326,0-2.598,0.527-3.536,1.464L8,10v15l0.536,0.536C9.473,26.473,10.745,27,12.071,27H20 c1.657,0,3-1.343,3-3v-1H20.5z"></path> </g></svg>
                            With over 26 years of seasoned global experience, I'm your go-to Freelance Consultant</h5>
                        <h5 className="banner-contact1 wow fadeInUp" data-wow-duration="1.2s" data-wow-delay="1.2s" rel="nofollow" >
                        <svg width="42px" height="32px" version="1.1" id="Uploaded to svgrepo.com" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 42 32"  fill="#82ec3c"><g id="SVGRepo_bgCarrier"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path class="stone_een" d="M7,25c0,0.552-0.448,1-1,1H1c-0.552,0-1-0.448-1-1V10c0-0.552,0.448-1,1-1h5c0.552,0,1,0.448,1,1V25z M20.5,23c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5H23v-3h-2.5c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5H23v-3h-2.5 c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5H30c1.105,0,2-0.895,2-2v0c0-1.105-0.895-2-2-2h-9l-1.535-1.535 C18.527,7.527,17.255,7,15.929,7h-2.858c-1.326,0-2.598,0.527-3.536,1.464L8,10v15l0.536,0.536C9.473,26.473,10.745,27,12.071,27H20 c1.657,0,3-1.343,3-3v-1H20.5z"></path> </g></svg>
                            I Specialize in Architecture, Programming, System Development, Modernisation, DevOps Automation</h5>


                    </div>

                    <div className="col-lg-4 col-sm-4 ml-auto" style={{ 'marginTop': '-38px' }}>
                        <VideoCard />
                    </div>
                </div>
                <div className="header-banner d-flex align-items-center special-header-banner" style={{ 'marginTop': '0px' }}>
                    <div className="banner-content">
                        <h5 className="banner-contact1 wow fadeInUp" data-wow-duration="1.2s" data-wow-delay="1.2s" rel="nofollow" >
                        <svg width="42px" height="32px" version="1.1" id="Uploaded to svgrepo.com" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 42 32"  fill="#82ec3c"><g id="SVGRepo_bgCarrier"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path class="stone_een" d="M7,25c0,0.552-0.448,1-1,1H1c-0.552,0-1-0.448-1-1V10c0-0.552,0.448-1,1-1h5c0.552,0,1,0.448,1,1V25z M20.5,23c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5H23v-3h-2.5c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5H23v-3h-2.5 c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5H30c1.105,0,2-0.895,2-2v0c0-1.105-0.895-2-2-2h-9l-1.535-1.535 C18.527,7.527,17.255,7,15.929,7h-2.858c-1.326,0-2.598,0.527-3.536,1.464L8,10v15l0.536,0.536C9.473,26.473,10.745,27,12.071,27H20 c1.657,0,3-1.343,3-3v-1H20.5z"></path> </g></svg>
                        I Deliver High Quality Software based on modern architectures, frameworks & cost effective solutions, while providing full end-to-end support</h5>
                    </div>
                </div>

                <div className="premuim-cards-highlights" style={{marginTop: '20px'}}>
                    <PremiumCard cardDetail={{ title: 'Gen AI - Early Adoption Services', link: '/ramesh/expertise', text: 'Integrating Gen AI - Context Windows, Agents, Text Actions, Assitants, Conversational ChatBOTs, RAG, into existing systems' }} />
                    <PremiumCard cardDetail={{ title: 'Expert Consulting Services', link: '/ramesh/expertise', text: 'Addressing specific technical challenges by diving deep into architecture, code reviews, NFRs, and development practices.' }} />
                    <PremiumCard cardDetail={{ title: 'Global Perspective', link: '/ramesh/', text: 'Worked in India, US, Singapore, UK. Working Relationship MSMEs & Startups Globally' }} />
                    <PremiumCard cardDetail={{ title: 'Top Clients', link: '/ramesh/', text: 'Tata, HDFC, IATA, Cognizant, Credit-Suisse, MSMEs, Several Startups, Training Instititues' }} />
                </div>

                <div className="premuim-cards-highlights" style={{marginTop: '30px'}}>
                <LinkButton link="/ramesh/about-me" text="About Me"  bgColor="#ffff00" borderColor="#00000f" textColor="#000"/>
                    <LinkButton link="/ramesh/samples/demos" text="Demo Apps"/>
                    <LinkButton link="/ramesh/samples/tech-diagrams" text="Technical Diagrams" />
                    <LinkButton link="/ramesh/skills" text="Skill Matrix"/>
                </div>
            </div>
        </section >
    );
}

export default PageTop;
