import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';

import ScrollToTop from './components/Common/ScrollToTop/ScrollToTop';

import Preloader from './components/Common/Preloader/Preloader';
import Header from './components/Common/Header/Header';
import Footer from './components/Common/Footer/Footer';

import HomePage from './Views/HomePage';
import Expertise from './Views/Expertise';
import SkillSet from './Views/SkillSet';
import ExperienceCurrent from './Views/ExperienceCurrent';
import FreeContentUseful from './Views/FreeContentUseful';
import SampleWork from './Views/SampleWork';
import AboutMe from './Views/AboutMe';
import ContactMe from './Views/ContactMe';
import CorporateTraining from './Views/Training';

import DemoApps from './components/SampleWorks/DemoApps/DemoApps';
import FeaturedWorks from './components/SampleWorks/FeaturedWorks';
import FeaturedTechDesignDocuments from './components/SampleWorks/FeaturedTechDesignDocuments';
import DiagramImages from './components/SampleWorks/DiagramImages/DiagramImages';

import TechnicalArticles from './components/FreeContent/TechnicalArticles';
import FreeTemplates from './components/FreeContent/FreeTemplates';
import TechnicalGuidelines from './components/FreeContent/TechnicalGuidelines';
import ContentSubjects from './components/FreeContent/ContentSubjects';
import Projects from './components/SampleWorks/Projects';

import Freelancer from './components/Resume/Freelancer';
import WorkExperience from './components/Resume/WorkExperience';
import AreasOfExpertise from './components/Resume/AreasOfExpertise';
import SkillMatrix from './components/Resume/SkillMatrix';
import Domains from './components/Resume/Domains';
import Education from './components/Resume/Education';
import Languages from './components/Resume/Languages';

import TrainingServices from './components/Training/TrainingServices';
import SampleTrainingContent from './components/Training/SampleTrainingContent';

import './AppUma.css';

class AppUma extends Component {

  render() {
    return (

      <div className="AppUma rameshv-container-main">
        <h1 style={{fontSize: '44px', color: 'green', textAlign:'center'}}>Watch this space....</h1>
        <h2 style={{color: 'orange', textAlign:'center'}}>Coming Soon.... http://uma-ramesh.me</h2>
        <br />
        <img src="images/uma-pic.gif" alt="Uma Works" width="400px" height="auto" style={{borderRadius: '12px', border: '18px solid #fff299', boxShadow: '0 0 0px  12px blue' }} />
      </div>
    );
  }
}

export default AppUma;