import React, { Component } from 'react';
import getfreeLancerJSON from './data/FreelancerJSON';

import '../Common/AppBodyCommon.css'
import './Resume.css';
import './Freelancer.css';

class FreelancerCard extends React.Component {

  render() {
    return (
      <div className="freelance-card">
        <div className="freelance-card-item">
          <div className="freelance-card-item-header-row">
            <div className="freelance-card-item-header">
              <div className="freelance-card-item-header-column">
                <div>{this.props.company}</div>
                <div style={{fontSize: '13px'}}>{this.props.startDate} - {this.props.endDate}</div>
              </div>
              <span style={{ width: '100%', textAlign: 'right' }}>{this.props.location}</span>
            </div>
          </div>
          <div className="freelance-card-item-content">
            {this.props.description}
          </div>
        </div>

        <div className="freelance-card-item">
          <div className="freelance-card-item-header-row">
            <span className="freelance-card-item-header-label" style={this.props.titleStyle}>
              Website:&nbsp;&nbsp;
            </span>
            <span className="freelance-card-item-content" style={{fontSize: '14px'}}>
              <a href="/">{this.props.website}</a>
            </span>
          </div>
        </div>

        <div className="freelance-card-item">
          <div className="freelance-card-item-header-row">
            <span className="freelance-card-item-header-label" style={this.props.titleStyle}>
              Clients:&nbsp;&nbsp;
            </span>
            <span className="freelance-card-item-content" style={{fontSize: '14px'}}>
              <span style={{ color: 'blue' }}>{this.props.clients}</span>
            </span>
          </div>
        </div>

        <div className="freelance-card-item">
          <div className="freelance-card-item-header-label" style={this.props.titleStyle}>
            Responsibilities
          </div>
          <div className="freelance-card-item-list">
            {this.props.responsibilities.map((responsibility, index) => (
              <div key={`domain${index}`} style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ width: '18px', paddingRight: '4px' }}>
                  <i className="fas fa-check" style={{ color: 'blue' }}></i>
                </div>
                <div style={{ width: '100%' }}>
                  {responsibility}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="freelance-card-item">
          <div className="freelance-card-item-header-label" style={this.props.titleStyle}>
            Projects
          </div>
          <div className="freelance-card-item-list">
            {this.props.projects.map((project, index) => (
              <div key={`domain${index}`} style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ width: '18px', paddingRight: 'px' }}>
                  <i className="fas fa-check" style={{ color: 'blue' }}></i>
                </div>
                <div style={{ width: '100%' }}>
                  <div dangerouslySetInnerHTML={{ __html: project }}></div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="freelance-card-item-techs" style={{ width: '100%' }}>
          <div className="freelance-card-item-header-label" style={this.props.titleStyle}>
            Technologies
          </div>

          <div className='freelance-tech-topic-parent'>
            {this.props.technologies !== undefined && this.props.technologies !== "" && (
              <div className="freelance-card-item-content" style={{ fontSize: '13px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }}>
                  <div className='freelance-tech-topic-header' style={{ borderTopLeftRadius: '0px' }}>
                    Tech Stacks
                  </div>
                  <div className='freelance-tech-topic-text' style={{ borderTopRightRadius: '0px' }}>
                    {this.props.technologies}
                  </div>
                </div>
              </div>
            )}
            {this.props.databases !== undefined && this.props.databases !== "" && (
              <div className="freelance-card-item-content" style={{ fontSize: '13px' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div className='freelance-tech-topic-header'>
                    Databases
                  </div>
                  <div className='freelance-tech-topic-text'>
                    {this.props.databases}
                  </div>
                </div>
              </div>
            )}
            {this.props.cloudservices !== undefined && this.props.cloudservices !== "" && (
              <div className="freelance-card-item-content" style={{ fontSize: '13px' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div className='freelance-tech-topic-header'>
                    Cloud Services
                  </div>
                  <div className='freelance-tech-topic-text'>
                    {this.props.cloudservices}
                  </div>
                </div>
              </div>
            )}
            {this.props.tools !== undefined && this.props.tools !== "" && (
              <div className="freelance-card-item-content" style={{ fontSize: '13px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }}>
                  <div className='freelance-tech-topic-header' style={{ borderBottomLeftRadius: '0px' }}>
                    Tools
                  </div>
                  <div className='freelance-tech-topic-text' style={{ borderBottomRightRadius: '0px' }}>
                    {this.props.tools}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

class Freealancer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FreealancerJSON: getfreeLancerJSON(),
    };
  }

  getJoinedTechString = (techArray) => {
    if (techArray !== undefined && techArray !== "") {
      return techArray.join(', ');
    }

    return "";
  }

  titleStyle = {
    paddingLeft: '0px',
    fontSize: '15px',
    color: 'black',
    fontVariant: 'small-caps'
  }

  render() {
    return (
      <div className='app-tab-item-container'>
        <div className='app-tab-item-container-items extra-top'>
          <div className='app-tab-item-container-item'>
            {this.state.FreealancerJSON.data.map((freelancer, index) => (
              <div key={index} style={{ width: '100%' }}>
                <FreelancerCard
                  titleStyle={this.titleStyle}
                  company={freelancer.company}
                  startDate={freelancer.start_date}
                  endDate={freelancer.end_date}
                  location={freelancer.location}
                  website={freelancer.website}
                  description={freelancer.description}
                  clients={freelancer.clients}
                  responsibilities={freelancer.responsibilities}
                  projects={freelancer.projects}
                  technologies={this.getJoinedTechString(freelancer.technologies)}
                  databases={this.getJoinedTechString(freelancer.databases)}
                  cloudservices={this.getJoinedTechString(freelancer.cloudservices)}
                  tools={this.getJoinedTechString(freelancer.tools)}
                />
                <br />
              </div>
            ))}

          </div>
        </div>
      </div>
    )
  }
}


export default Freealancer;

