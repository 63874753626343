import React, { Component } from 'react';

import BulletedTree from '../Common/Controls/BulletedTree';

import getTechnicalArticlesJSON from './data/TechArticlesJSON';

import '../Common/AppBodyCommon.css';
import './FreeContent.css';
import './TechnicalArticles.css';

class TechnicalArticles extends Component {
  filterJSON = ["Architecture & Tech Design", "Java", "Microsoft", "Cloud Service Providers & Services", "Databases & Data Engineering", "Specific Technologies", "Setup", "Build & Deploy", "General"];


  titleStyle = {
    fontSize: '15px',
    FontFace: 'Arial',
    fontVariant: 'small-caps',
    padding: '2px 2px 2px 5px',
    boxShadow: 'rgba(0, 0, 0, 0.5) 1px 1px 2px 1px',
    borderRadius: '4px',
    marginTop: '12px',
    marginBottom: '12px',
    backgroundColor: 'rgba(250, 250, 212, .7)',
    width: 'fit-content',
    paddingRight: '6px',
    paddingBottom: '4px'
  }

  subListStyle2 = { paddingTop: '2px', paddingLeft: '2px' };

  render() {
    return (
      <section id="skillsset2" className="services-area pt-20 gray-bg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-20">
              <div className="section-title text-center">
                <h5 className="sub-title mb-15">Access Technical Articles</h5>
              </div>
            </div>
          </div>
        </div>

        <div>
          <BulletedTree title="" data={getTechnicalArticlesJSON().data} titleStyle={this.titleStyle} anchorInNewWindow={false} filter={this.filterJSON} subListStyle={this.subListStyle2} showParentBullet={false} />
        </div>
      </section>
    )
  }
};

export default TechnicalArticles;

