import React, { Component } from 'react';
import { Route, Routes, Outlet } from 'react-router-dom';

import * as ReactDOM from "react-dom";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import ScrollToTop from './components/Common/ScrollToTop/ScrollToTop';

import Preloader from './components/Common/Preloader/Preloader';

import './App.css';

import AppLayout from './AppLayout';
import AppRamesh from './AppRamesh';
import AppUma from './AppUma';



class App extends Component {

  render() {
    return (

      <div className="App">

        <Routes>
          <Route path="" Component={AppLayout} />
          <Route path="ramesh/*" Component={AppRamesh} />
          <Route path="uma" Component={AppUma} />
        </Routes>
        <ScrollToTop></ScrollToTop>
      </div>
    );
  }
}

export default App;

