import React from "react";
import SkillMatrix from "../components/Resume/SkillMatrix";

export default class SkillSet extends React.Component {
    render() {
        return (
            <div id="skillset" className="skills-container">
                <section id="skills" className="services-area pt-175 gray-bg" style={{ 'alignSelf': 'start' }} >
                    <div className="container">
                        <div className="row justify-content-left">
                            <div className="section-title-containter">
                                <div className="section-title text-left pb-20" style={{ 'width': '100%' }}>
                                    <h5 className="sub-title mb-15">My Skillset</h5>
                                    <h2 className="title" style={{ 'width': '100%' }}>Worked extensively on these...</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <SkillMatrix />
            </div>
        )
    }
}