import React from 'react';
import './Contact.css';

function Contact() {
    return (
        <section id="contact" className="contact-area rameshv-container-col" >
            <div className="col-lg-12">
                <div className="section-title text-center pb-20">
                    <h5 className="sub-title mb-15">Contact us</h5>
                    <h2 className="title">Get In touch</h2>
                </div>
                <div className="col-lg-12 contact-container">
                    <div className="contact-form">
                        <form id="contact-form" action="/interio-assets/contact.php" method="post" data-toggle="validator">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="single-form form-group">
                                        <input type="text" name="name" placeholder="Your Name" data-error="Name is required." required="required" autoComplete="on" />
                                        <div className="help-block with-errors"></div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="single-form form-group">
                                        <input type="email" name="email" placeholder="Your Email" data-error="Valid email is required." required="required" autoComplete="on" />
                                        <div className="help-block with-errors"></div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="single-form form-group">
                                        <input type="text" name="subject" placeholder="Subject" data-error="Subject is required." autoComplete="on" />
                                        <div className="help-block with-errors"></div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="single-form form-group">
                                        <input type="text" name="phone" placeholder="Phone" data-error="Phone is required." autoComplete="on" />
                                        <div className="help-block with-errors"></div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="single-form form-group">
                                        <textarea placeholder="Your Mesaage" name="message" data-error="Please,leave us a message." required="required"></textarea>
                                        <div className="help-block with-errors"></div>
                                    </div>
                                </div>
                                <p className="form-message"></p>
                                <div className="col-md-12">
                                    <div className="single-form form-group text-center">
                                        <button type="submit" className="main-btn">send message</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact;
