import React from 'react';
import './About.css';

function About() {
    return (
        <section id="about" className="rameshv-container-col">
            <div className="col-lg-12">
                <div className="section-title text-center pb-10">
                    <h5 className="title">About Me</h5>
                    <h2 className="sub-title mb-15">Reasons to choose</h2>
                </div>
            </div>

            <div className="col-lg-12 pb-20 about-container">
                <div className="main-btn2 fadeInRight2" data-wow-duration=".7s" data-wow-delay=".7s" rel="nofollow"><span>26</span> Years Experience</div>

                <div className="main-btn2 fadeInLeft2" data-wow-duration=".7s" data-wow-delay=".7s" rel="nofollow">Global Perspective</div>
                
                <div className="main-btn2 fadeInLeft2" data-wow-duration=".7s" data-wow-delay=".7s" rel="nofollow">Scientific Methodology</div>
                <div className="main-btn2 fadeInRight2" data-wow-duration=".7s" data-wow-delay=".7s" rel="nofollow">Professional Approach</div>
            </div>
            <div className="col-lg-12 w-100 about-with-image">
                <div className="about-content">
                    <p>
                        Welcome to my digital hub! I am here to help with your software projects with a blend of architectural design, programming excellence, and automation expertise. Explore the range of freelance services I offer on this website.
                        <br /><br />
                        With experience across India, the US, UK, and Singapore, I bring a global perspective to every project.
                        <br /><br />
                        I am passionate about mastering diverse technologies and continuously updating my skills. I specialize in .NET, Java, NodeJS, Python, C++, PHP, and their related frameworks. My focus areas include end-to-end system system development based on modern architecures, process automation, and cloud/on-prem infrastructure, delivering cost-effective and innovative solutions tailored specific to client needs.
                        <br /><br />
                        Since 1997, I have collaborated with a diverse range of clients—from global giants like TATA Group, HDFC, IATA, and Credit-Suisse, to MSMEs, Consulting companies. I’ve gained significant hands-on experience with startups.
                    </p>
                </div>
                <div className="about-image">
                    <img src="/images/ramesh-pic.gif" width="350px" height="350px" alt="about-me" />
                </div>
            </div>
            <div className="about-content w-100" style={{paddingLeft:'14px'}}>
                    <p>
                        <br />
                    I assist my clients with RFPs, sales, architecure & system reviews, and modernization initiatives. I am domain-agnostic, with a sharp ability to grasp client requirements, effectively communicate solutions, and provide clear, actionable documentation. My goal is to simplify complex challenges and turn them into effective solutions.
                        <br /><br />
                        Ultimately, I believe professionalism is key to success. My work revolves around delivering on time, ensuring quality, collaborating seamlessly, and mentoring younger team members to grow together as a team.
                    </p>
                </div>
        </section >
    );
}

export default About;
