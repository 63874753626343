import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const PremiumCard = ({ cardDetail }) => {
    return (
        <StyledWrapper>
            <NavLink
                to={cardDetail.link}>
                <div className="container">
                    <div className="card_box">
                        <div className="card_box_inner">
                            <span className="card_box_content">
                                <span style={{ fontSize: '12px', fontWeight: '500', color: 'yellow', marginLeft:'-15px', fontVariant: 'small-caps' }}>{cardDetail.title}</span>
                                <span style={{ fontSize: '11px', color: 'white', marginLeft:'-40px', marginTop:'5px', fontFamily: 'cursive', verticalAlign:'middle', height: '100%' }}>{cardDetail.text}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </NavLink>
        </StyledWrapper>
    );
}

const StyledWrapper = styled.div`
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .card_box .card_box_inner {
    width: 300px;
    height: 90px;
    border-radius: 20px;
    background: linear-gradient(170deg, rgba(58, 56, 56, 0.623) 0%, rgb(31, 31, 31) 100%);
    position: relative;
    box-shadow: 0 25px 50px rgba(0,0,0,0.55);
    cursor: pointer;
    transition: all .3s;
  }

  .card_box .card_box_inner .card_box_content {
  display: flex;
  flex-direction: column;
    margin-left:80px;
    padding:6px;
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
    transition: all .3s;
  }


  .card_box:hover {
    transform: scale(0.9);
  }

  .card_box div {
  position: absolute;
  overflow: hidden;
  width: 150px;
  height: 150px;
  top: -10px;
  left: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card_box div::before {
  content: 'Highlight';
  position: absolute;
  width: 100%;
  height: 25px;
  font-size: .60rem;
  background-image: linear-gradient(45deg, #ff6547 0%, #ffb144  51%, #ff7053  100%);
  transform: rotate(-45deg) translateY(-100px) translateX(-75px) ;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  box-shadow: 0 5px 10px rgba(0,0,0,0.23);
}

.card_box div::after {
  content: '';
  position: absolute;
  width: 10px;
  bottom: 0;
  left: 0;
  height: 10px;
  z-index: -1;
  box-shadow: 140px -140px #cc3f47;
  background-image: linear-gradient(45deg, #FF512F 0%, #F09819  51%, #FF512F  100%);
}`;

export default PremiumCard;
