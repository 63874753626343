import React from "react"

import Resume from "../components/Resume/Resume"

import './ExperienceCurrent.css'

export default class ExperienceCurrent extends React.Component {
    render() {
        return (
            <section id="experience-current" className="services-area pt-175 gray-bg rameshv-container-col">
                <div className="container">
                    <div className="row justify-content-left">
                        <div className="col-lg-6">
                            <div className="section-title text-left pb-20">
                                <h5 className="title mb-15">My Past Work</h5>
                                <h2 className="sub-title" style={{ width: '100%' }}>As a seasoned Software Engineer with 26 years of experience, I specialize in <span style={{color: 'blue', fontStyle: 'oblique', fontWeight: '500'}}>leading, managing, architecting, designing, and programming</span>. I have a proven track record of delivering comprehensive IT solutions across various project phases of scale & complexities, in multitude of tech stacks, and business domains.</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <Resume />
            </section>
        )
    }
}