import React, { Component } from 'react';

import { US, IN } from 'country-flag-icons/react/3x2'

import getEducationJSON from './data/EducationJSON';

import '../Common/AppBodyCommon.css'
import './Resume.css';
import './Education.css';

class Education extends Component {

  render() {
    return (
      <div className='app-tab-item-container'>
        <div className='app-tab-item-container-items extra-top'>
          <div className='app-tab-item-container-item'>
            {getEducationJSON().data.map((node, index) => (
              <div key={index} className='edu-row-item' style={{}}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: '80%' }}>
                    <span className='edu-text-special'>{node.degree}, {node.field_of_study}</span>
                    <br></br>
                    <span className='edu-text'>{node.university}</span>
                  </div>
                  <div style={{ width: '20%', textAlign: 'right' }}>
                    <img src={node.icon} alt='' className='edu-icon' />
                  </div>
                </div>
                <div className='edu-text-country-row'>
                  <div className='edu-text'>{node.year}</div>
                  <div style={{ width: '100%', textAlign: 'right', paddingRight: '6px' }}>
                    {node.location.toString().endsWith("India") === true ? (
                      <IN title="India" className="edu-country-flag" />) : null
                    }
                    {node.location.toString().endsWith("United States") === true ? (
                      <US title="USA" className="edu-country-flag" />) : null
                    }
                    &nbsp;{node.location}
                  </div>

                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
};

export default Education;

