import React from 'react';
import { NavLink } from 'react-router-dom';

import getServiceOfferings from '../../../Views/data/service-offerings';

import './Footer.css';

var services = getServiceOfferings().data

function Footer() {

    return (

        <footer id="footer" className="rameshv-container-row footer-container mt-20 col-lg-12">
            <div className="cookieCard col-lg-3">
                <p className="cookieHeading">Contact Info</p>
                <div className="cookieDescription footer-info-container" style={{ 'alignSelf': 'start' }}>
                    <div className="single-info">
                        <div className="info-icon">
                            <i className="lni-phone-handset"></i>
                        </div>
                        <div className="info-content">
                            <p>+91 875 449 1947</p>
                        </div>
                    </div>

                    <div className="single-info">
                        <div className="info-icon">
                            <i className="lni-envelope"></i>
                        </div>
                        <div className="info-content">
                            <a href="mailto:contact@rameshv.me" style={{color: 'blue', fontSize: '16px'}}>contant@rameshv.me</a>
                        </div>
                    </div>

                    <div className="single-info">
                        <div className="info-icon">
                            <i className="lni-map"></i>
                        </div>
                        <div className="info-content">
                            <p>Kovalam, Chennai, Tamil Nadu, India</p>
                        </div>
                    </div>

                    <div className="single-info justify-items-center">
                        <div className="info-icon">
                            <i className="lni-linkedin-filled mt-1"></i>
                        </div>
                        <div className="info-content mt-1">
                            <a href="https://www.linkedin.com/in/ramesh-viswanathan-me/" style={{fontSize: '16px', color: 'blue'}}>linkedin.com/in/ramesh-viswanathan-me/</a>
                        </div>
                    </div>

                </div>
            </div>


            <div className="footer-link footer-sub row align-items-right" style={{border: '2px solid white', borderRadius: '24px', boxShadow: 'inset 0px 0px 2px 4px'}}>
                <div className="f-title">
                    <h4 className="title mb-6" style={{ 'display': 'grid', 'justifyItems': 'end', 'color': 'orange', 'fontSize': '26px'}}><NavLink to="/ramesh/expertise" >Services</NavLink></h4>
                </div>
                <hr className='hr-bar2'></hr>
                <div className="flex">
                    {services.map((service, index) => (
                        <div key={index}>
                            <strong style={{ 'color': 'orange', 'fontWeight': 'bold' }}> | </strong>
                            <span className="service-text" >{service.title} Services</span>
                            <strong style={{ 'color': 'orange', 'fontWeight': 'bold' }}> | </strong>

                        </div>
                    ))}
                <div className="nav-item" style={{ 'fontSize': '16px'}}>
                <strong style={{ 'color': 'orange', 'fontWeight': 'bold' }}> | </strong>
                        <NavLink to="/ramesh/training/services" >Corporate Training</NavLink>
                        <strong style={{ 'color': 'orange', 'fontWeight': 'bold' }}> | </strong>
                    </div>
                </div>

                
            </div>

            <div className="cookieCard col-lg-3 justify-content-start">
                <p className="cookieHeading mt-10">Other Links</p>
                <div style={{'display': 'flex', 'flexDirection': 'column', 'rowGap': '10px', 'textAlign': 'center'}}>
                    <div className="nav-item" style={{ 'fontSize': '18px', fontVariant: 'small-caps'}}>
                        <NavLink to="/ramesh/work-exp/freelancer" >Experience</NavLink>
                    </div>
                    <div className="nav-item" style={{ 'fontSize': '18px', fontVariant: 'small-caps' }}>
                        <NavLink to="/ramesh/free-content/content-subjects" >Free Content</NavLink>
                    </div>
                </div>
            </div>
        </footer >
    );
}

export default Footer;
